import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import API from "services/Api";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import { IDLE, LOADING } from "constants/page-state";
import Spinner from "components/layout/Spinner";
import Sections from "../contest/Sections";
import { DOMA_LEVELS } from "constants/constants";
import ParticipantScoreRanking from "./ParticipantScoreRanking";

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  main: {
    minHeight: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  sectionSelect: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },

  lastUpdated: {
    textAlign: "left",
  },
  table: {
    width: "80%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  cellPos: {
    padding: 0,
    maxWidth: 32,
  },
});

class Ranking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageState: LOADING,
      selectedDomaLevelIndex: "",
      selectedYearIndex: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    const params = new URLSearchParams(window.location.search);
    this.setState({
      selectedYear: Number(params.get("year")),
      selectedDomaLevel: Number(params.get("domaLevel")),
    });

    API.ranking
      .getYears()
      .then((years) => {
        if (this.state.selectedYear) {
          const selectedYearIndex = years
            .findIndex((y) => y === this.state.selectedYear)
            .toString();
          setTimeout(() => this.changeYear(selectedYearIndex, false), 50);
        }

        this.setState({
          pageState: IDLE,
          years,
        });
      })
      .catch((error) => {
        console.error("Error loading api ranking", error);
      });
  }

  changeYearSection = (event) => {
    this.changeYear(event.target.value);
  };

  changeYear = (index, resetHistory = true) => {
    const year = this.state.years[index];

    if (resetHistory) {
      this.saveHistory(year);
    }

    this.setState({
      selectedYear: year,
      selectedYearIndex: index,
      domaLevel: null,
      selectedDomaLevel: resetHistory ? null : this.state.selectedDomaLevel,
      selectedDomaLevelIndex: "",
      domaLevels: null,
      pageState: LOADING,
    });

    API.ranking
      .getDomaLevels(year)
      .then((domaLevels) => {
        if (this.state.selectedDomaLevel) {
          const selectedDomaLevelIndex = domaLevels
            .findIndex((y) => y === this.state.selectedDomaLevel)
            .toString();
          setTimeout(() => this.changeDomaLevel(selectedDomaLevelIndex), 50);
        }

        this.setState({
          pageState: IDLE,
          domaLevels,
        });
      })
      .catch((error) => {
        console.error("Error loading api ranking", error);
      });
  };

  changeDomaLevelSection = (event) => {
    this.changeDomaLevel(event.target.value);
  };

  changeDomaLevel = (index) => {
    const domaLevel = this.state.domaLevels[index];

    this.saveHistory(this.state.selectedYear, domaLevel);
    this.setState({
      selectedDomaLevel: domaLevel,
      selectedDomaLevelIndex: index,
      pageState: LOADING,
    });

    API.ranking
      .getRanking(this.state.selectedYear, domaLevel)
      .then((ranking) => {
        this.setState({
          pageState: IDLE,
          rankingUpdated: ranking.updated,
          ranking: ranking.ranking,
        });
      })
      .catch((error) => {
        console.error("Error loading api ranking", error);
      });
  };

  saveHistory(year, domaLevel) {
    let query = new URLSearchParams({
      year,
    });

    if (domaLevel) {
      query.append("domaLevel", domaLevel);
    }

    this.props.history.push({
      search: "?" + query.toString(),
    });
  }

  resize() {
    let res = "xs";
    if (window.innerWidth < this.props.theme.breakpoints.values["sm"]) {
      res = "xs";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["md"]) {
      res = "sm";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["lg"]) {
      res = "md";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["xl"]) {
      res = "lg";
    } else {
      res = "xl";
    }

    if (this.state.currentBreakpoint !== res) {
      this.setState({ currentBreakpoint: res });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      pageState,
      years,
      selectedYearIndex,
      domaLevels,
      selectedDomaLevelIndex,
      currentBreakpoint,
      ranking,
      rankingUpdated,
    } = this.state;
    const isMobile = ["xs", "sm"].includes(currentBreakpoint);

    return (
      <Box>
        <Header></Header>
        <Box className={classes.main}>
          <Typography className={classes.title} variant="h4">
            RANKING DOMA CLÁSICA
          </Typography>
          <Box className={classes.sectionSelect}>
            {years && (
              <Sections
                isMobile={isMobile}
                sections={years}
                section={selectedYearIndex}
                changeSelectSection={this.changeYearSection}
                changeButtonGroupSection={this.changeYear}
              />
            )}
          </Box>

          <Box className={classes.sectionSelect}>
            {domaLevels && (
              <Sections
                isMobile={isMobile}
                sections={domaLevels.map((dl) => DOMA_LEVELS[dl])}
                section={selectedDomaLevelIndex}
                changeSelectSection={this.changeDomaLevelSection}
                changeButtonGroupSection={this.changeDomaLevel}
              />
            )}
          </Box>

          {pageState === LOADING && <Spinner />}

          {ranking && (
            <div>
              <Typography className={classes.lastUpdated} variant="body1">
                Último ranking publicado: {rankingUpdated}
              </Typography>
              <Table className={classes.table}>
                {!isMobile && (
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cellPos} align="center">
                        POS.
                      </TableCell>
                      <TableCell>EJEMPLAR</TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <b>PADRE</b>
                        </Typography>
                        <Typography variant="body2">
                          <b>MADRE</b>
                        </Typography>
                      </TableCell>
                      <TableCell>JINETE</TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <b>TITULAR</b>
                        </Typography>
                        <Typography variant="body2">
                          <b>CRIADOR</b>
                        </Typography>
                      </TableCell>
                      <TableCell>PUNTUACIONES</TableCell>
                      <TableCell>TOTAL</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                )}

                <TableBody>
                  {ranking.map((p, i) => (
                    <ParticipantScoreRanking
                      key={i}
                      participant={p}
                      isMobile={isMobile}
                      previous={i - 1}
                      list={this.participants}
                      next={i + 1}
                    ></ParticipantScoreRanking>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </Box>
        <Footer></Footer>
      </Box>
    );
  }
}

Ranking.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(Ranking));
