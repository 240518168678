import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
})


class Scores extends React.Component {

  render() {
    return (
      <Box>
        { this.props.scores.map((score, key) =>
          <Typography key={key} noWrap align="right" variant="body2">
            {score.name}: <b>{score.punt}</b>
          </Typography>
        )}
      </Box>
    )
  }
}


Scores.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Scores)
