import { RANKING_MORF } from "services/fixtures"

function URL(path) {
  return process.env.REACT_APP_API_URL + path;
}

function customFetch(path) {
  let data = { method: "GET", headers: { "Content-Type": "application/json" } };
  return fetch(URL(path), data)
    .then((resp) => {
      if (resp.status !== 200) {
        let error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      }
      return resp.json();
    })
    .catch((error) => {
      console.error("Error in the API call: ", error);
      throw error;
    });
}

function genQueryParams(object) {
  let queryParams = "?";
  for (let key in object) {
    let value = object[key];
    if (!value) {
      continue;
    }
    queryParams += `${key}=${value}&`;
  }
  return queryParams.substring(0, queryParams.length - 1);
}

const API = {
  videos: {
    getAds: (videoUrl) => customFetch(`/videos/ads/?video=${videoUrl}`),
  },
  ads: {
    get: () => {
      return customFetch(`/public/ads/concursos/`);
    },
  },
  contest: {
    getContests: (discipline, query) => {
      return customFetch(`/public/contests/${query}&discipline=${discipline}`);
    },
    getContest: (code, params) => {
      let queryParams = genQueryParams(params);
      return customFetch(`/public/contest/${code}/${queryParams}`);
    },
    getSchedule: (code) => {
      return customFetch(`/public/contest/${code}/schedule/`);
    },
    getLivestocks: (code, discipline) => {
      return customFetch(
        `/public/contest/${code}/livestock/?discipline=${discipline}`
      );
    },
    getLivestockRank: (code, discipline) => {
      if (discipline === "morf") {
        return customFetch(`/public/contest/${code}/score-livestock/`).catch(
          (err) => []
        );
      }
      return new Promise((resolve) => {
        resolve({});
      });
    },
    getBreederRank: (code, discipline) => {
      if (discipline === "morf") {
        return customFetch(`/public/contest/${code}/score-breeder/`).catch(
          (err) => []
        );
      }
      return new Promise((resolve) => {
        resolve({});
      });
    },
  },
  home: {
    getCustom: () => {
      return customFetch("/public/home-custom/");
    },
  },
  participant: {
    searchParticipant: (search) => {
      return customFetch(`/public/participant/${search}`);
    },
  },
  judge: {
    getList: () => {
      return customFetch(`/public/judges/`);
    },
    searchJudge: (query) => {
      return customFetch(`/public/judge/?${query}`);
    },
  },
  sponsor: {
    getList: (year, discipline) => {
      return customFetch(`/public/sponsor/${year}/?discipline=${discipline}`);
    },
  },
  dressage: {
    bestBreeder: (code) => {
      return customFetch(`/public/contest/doma/${code}/best-breeder/`);
    },
  },
  report: {
    years: () => {
      return customFetch(`/dashboard/years/`);
    },
    judgeChanges: (contest_code) => {
      return customFetch(`/dashboard/report/judges/changes/${contest_code}/`);
    },
  },
  ranking: {
    getYears: () => {
      return customFetch("/public/dressage/ranking/");
    },
    getDomaLevels: (year) => {
      return customFetch(`/public/dressage/ranking/${year}/`);
    },
    getRanking: (year, domaLevel) => {
      return customFetch(`/public/dressage/ranking/${year}/${domaLevel}/`);
    },
  },
  rankingMorf: {
    getRanking: (year, classification, section) => {
      const queryParams = genQueryParams({
        year,
        section,
        classification,
        discipline: "MORPHOLOGY",
      });
      return customFetch(`/public/ranking${queryParams}`);
    },
    fake: (year, classification, section) => {
      if (section === 3) {
        return Promise.resolve({
          2022: {I: RANKING_MORF, N: RANKING_MORF},
        });
      }
      return Promise.resolve({
        2019: {},
        2020: {},
        2021: {
          E: {
            3: {
              section_name: "S3",
              updated: "7 de Abril de 2021 a las 12:17",
              ranking: [],
            },
            4: {
              section_name: "S4",
              updated: "",
              ranking: [],
            },
          },
        },
        2022: {
          I: {
            3: {
              section_name: "S3",
              updated: "7 de Abril de 2021 a las 12:17",
              ranking: [],
            },
            4: {
              section_name: "S4",
              updated: "",
              ranking: [],
            },
          },
          N: {
            3: {
              section_name: "S3",
              updated: "7 de Abril de 2021 a las 12:17",
              ranking: [],
            },
            4: {
              section_name: "S4",
              updated: "",
              ranking: [],
            },
          },
        },
      });
    },
  },
  rankingOD: {
    getRanking: (discipline, year, section) => {
      const queryParams = genQueryParams({ discipline, year, section });
      return customFetch(`/public/${discipline}/ranking/${queryParams}`);
    },
  },
  rankingFake: {
    getYears: () => {
      return new Promise((resolve) => {
        setTimeout(() => resolve([2018, 2019, 2020, 2021]), 200);
      });
    },
    getDomaLevels: (year) => {
      return new Promise((resolve) => {
        setTimeout(() => resolve([1, 2, 3, 5, 7, 8, 9, 11]), 150);
      });
    },
    getRanking: (year, domaLevel) => {
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              updated: "fecha X",
              ranking: [
                {
                  position: 1,
                  horse: "OCEANO XXV",
                  code: "724015120292374",
                  father: "ENGANYOSO VI",
                  mother: "OCEANICA VIII",
                  rider: "MARGARITA GARCIA",
                  breeder: "YEGUADA ORELLANA",
                  livestock: "EL CORNIJAL",
                  test_number: 3,
                  scores: [43.35, 40.3, 32.25],
                  total_score: "120.900",
                },
                {
                  position: 2,
                  horse: "MAR XXV",
                  code: "724015120292374",
                  father: "ENGANYOSO VI",
                  mother: "OCEANICA VIII",
                  rider: "MARGARITA GARCIA",
                  breeder: "YEGUADA ORELLANA",
                  livestock: "EL CORNIJAL",
                  test_number: 3,
                  scores: [43.35, 40.3],
                  total_score: "80.123",
                  classified: true,
                },
                {
                  position: 3,
                  horse: "MONTAÑA XXV",
                  code: "724015120292374",
                  father: "ENGANYOSO VI",
                  mother: "OCEANICA VIII",
                  rider: "MARGARITA GARCIA",
                  breeder: "YEGUADA ORELLANA",
                  livestock: "EL CORNIJAL",
                  test_number: 3,
                  scores: [43.35],
                  total_score: "43.350",
                },
              ],
            }),
          100
        );
      });
    },
  },
};

//setInterval(() => {
//    let position = store.getState().position;
//    if (position != null) {
//        API.judge.ping(position.section, position.number, position.func);
//    }
//}, 5000);

export default API;
