import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { TableCell, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  link: {
    color: "black",
    fontSize: 16,
    textDecoration: "underline",
  },
  grey: {
    color: "grey"
  },
});


class ParticipantPE extends React.Component {
  render() {
    const { classes } = this.props

    if (!this.props.participant) {
      return (<TableCell> </TableCell>)
    }

    if (this.props.participant.number === null && this.props.participant.name === null) {
      return (
        <TableCell className={this.props.className}>
          <Typography variant="body1">
            -
          </Typography>
        </TableCell>
      )
    }

    if ('MG' === this.props.code) {
      return (
        <TableCell>
          {this.props.participant.breeder}
        </TableCell>
      )
    }
    if ('ME' === this.props.code) {
      return (
        <TableCell>
          {this.props.participant.livestock}
        </TableCell>
      )
    }

    return (
      <TableCell className={this.props.className}>
        <Link className={classes.link} to={ "/participant?code=" + this.props.participant.code }>
          {`${this.props.participant.number} - ${this.props.participant.name}`}<br/>
        </Link>
        <Typography variant="body2">
          {this.props.participant.livestock}
        </Typography>
        <Typography className={classes.grey} variant="body2">
          {this.props.participant.breeder}
        </Typography>
      </TableCell>
    )
  }
}

ParticipantPE.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantPE)

