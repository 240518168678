import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import ParticipantPE from "components/pages/participant/ParticipantPE.js";
import SpecialAwardTablesCollapse from "components/pages/contest/SpecialAwardTablesCollapse.js";
import multimedia from "assets/contest/multimedia.png";
import DialogMultimediaPE from "components/layout/DialogMultimediaPE";

const styles = (theme) => ({
  box: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px 0px",
    },
  },
  table: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableBody: {
    backgroundColor: "#f1f1f1",
  },
  title: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(0.5),
  },
  cell: {
    padding: "8px 32px",
    lineHeight: 1.4,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px",
    },
  },
  cellAward: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    padding: "8px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px",
    },
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
    filter: "invert()",
  },
});

class ContestSectionPE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dialogTitle: "",
      dialogDesc: "",
      dialogVideo: "",
    };
  }

  handleDialog = (
    e,
    title,
    desc,
    video,
    previous,
    next,
    hasRunnerUp,
    tablesDefinitions,
    orderedList
  ) => {
    e.stopPropagation();

    this.setState({
      open: !this.state.open,
      dialogTitle: title,
      dialogDesc: desc,
      dialogVideo: video,
      previous: previous.champion,
      next: next.champion,
      tablesDefinitions,
      orderedList,
    });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleClose = (e) => {
    this.setState({ open: false });
  };

  groupBy = (arr, prop) => {
    let res = { manual: [] };
    arr.forEach((e) => {
      if (e["type"] === "manual") {
        // Second ME and MG remove here
        if (
          !e["name"].toLowerCase().startsWith("segunda mejor ganader") &&
          !e["name"].toLowerCase().startsWith("segundo mejor expositor")
        ) {
          res["manual"].push(e);
        }
      } else {
        res[e[prop]] = e;
      }
    });
    return res;
  };

  existPE = (rows, pe) => {
    let res = false;
    rows.forEach((row) => {
      res += pe[row.code];
    });
    return res;
  };

  render() {
    const { classes } = this.props;
    const pe = this.groupBy(this.props.pe, "name");

    const tablesDefinitions = [
      {
        title: "Campeones Jóvenes del Concurso",
        head: ["Premio", "Campeón", "Subcampeón"],
        rows: [
          { code: "CCHJ", name: "Hembra" },
          { code: "CCMJ", name: "Macho" },
        ],
      },
      {
        title: "Campeones Adultos del Concurso",
        head: ["Premio", "Campeón", "Subcampeón"],
        rows: [
          { code: "CCH", name: "Hembra" },
          { code: "CCM", name: "Macho" },
        ],
      },
      {
        title: "Campeón de Funcionalidad",
        head: ["Premio", "Campeón", "Subcampeón"],
        rows: [{ code: "CF", name: "Adulto" }],
      },
      {
        title: "Mejor Ganadería",
        head: ["Premio", "Mejor", "Segundo mejor"],
        rows: [
          { code: "MG", name: "Criador" },
          { code: "ME", name: "Expositor" },
        ],
      },
    ];

    if (this.props.regulation === "2016") {
      tablesDefinitions.unshift({
        title: "Mejores Movimientos del Concurso",
        head: ["Premio", "Campeón", "Subcampeón"],
        rows: [{ code: "CMM", name: "Todos" }],
      });
    } else if (this.props.regulation >= "2020") {
      tablesDefinitions.unshift(
        {
          title: "Campeones Jóvenes de Mejores Movimientos del Concurso",
          head: ["Premio", "Campeón", "Subcampeón"],
          rows: [{ code: "CMMJS", name: "Jóven" }],
        },
        {
          title: "Campeones Adultos de Mejores Movimientos del Concurso",
          head: ["Premio", "Campeón"],
          rows: [
            { code: "CMMH", name: "Hembra" },
            { code: "CMMM", name: "Macho" },
          ],
        }
      );
    } else {
      tablesDefinitions.unshift({
        title: "Mejores Movimientos del Concurso",
        head: ["Premio", "Campeón"],
        rows: [
          { code: "CMMJ", name: "Jóven" },
          { code: "CMM", name: "Adulto" },
        ],
      });
    }

    const tdf = tablesDefinitions
      .filter((d) => this.existPE(d.rows, pe))
      .map((r) => ({ ...r.rows.map((rw) => rw.code) }))
      .reduce((acc, i) => [...acc, ...Object.values(i)], []);

    const order = [];
    tdf.forEach((td) => {
      const p = this.props.pe.find((l) => l.name === td);
      if (p !== undefined && p.media) {
        order.push(p);
      }
    });

    return (
      <Box className={classes.box}>
        {tablesDefinitions
          .filter((d) => this.existPE(d.rows, pe))
          .map((def, i) => {
            return (
              <Box key={i}>
                <Typography className={classes.title} variant="h5">
                  {def.title}
                </Typography>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {def.head.map((head, ih) => {
                        return (
                          <TableCell
                            className={classes.cell}
                            key={i + "h" + ih}
                          >
                            {head}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {def.rows.map((row, ir) => {
                      if (!pe[row.code]) {
                        return <TableRow key={i + "r" + ir}></TableRow>;
                      }

                      let score_tables = Object.entries(
                        this.props.pe_scores[row.code] || {}
                      );

                      return (
                        <React.Fragment key={i + "r" + ir}>
                          <TableRow>
                            <TableCell className={classes.cellAward}>
                              {row.name}
                              {pe[row.code]["media"] && (
                                <Button
                                  className={classes.multimedia}
                                  color="primary"
                                  onClick={(e) =>
                                    this.handleDialog(
                                      e,
                                      def.title,
                                      row.name,
                                      pe[row.code]["media"],
                                      pe[
                                        tdf[
                                          tdf.findIndex(
                                            (e) => e === row.code
                                          ) === 0
                                            ? 0
                                            : tdf.findIndex(
                                                (e) => e === row.code
                                              ) - 1
                                        ]
                                      ],
                                      pe[
                                        tdf[
                                          tdf.findIndex((e) => e === row.code) +
                                            1
                                        ]
                                      ],
                                      def.head.length === 3,
                                      tablesDefinitions,
                                      order
                                    )
                                  }
                                >
                                  <img
                                    className={classes.multimediaImg}
                                    src={multimedia}
                                    alt="multimedia"
                                  />
                                </Button>
                              )}
                            </TableCell>
                            <ParticipantPE
                              className={classes.cell}
                              participant={pe[row.code]["champion"]}
                              code={row.code}
                            />
                            {def.head.length === 3 && (
                              <ParticipantPE
                                className={classes.cell}
                                participant={pe[row.code]["runner_up"]}
                                code={row.code}
                              />
                            )}
                          </TableRow>

                          {score_tables.length > 0 && (
                            <SpecialAwardTablesCollapse
                              score_tables={score_tables}
                            />
                          )}

                          <TableRow
                            className={classes.extraWhiteCell}
                          ></TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            );
          })}

        {pe.manual.length > 0 && (
          <Box>
            <Typography className={classes.title} variant="h5">
              Otros premios
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Premio</TableCell>
                  <TableCell className={classes.cell}>Campeón</TableCell>
                  <TableCell className={classes.cell}>Subcampeón</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {pe.manual.map((row, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell className={classes.cellAward}>
                        {row.name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.champion.name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.runner_up.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}

        <DialogMultimediaPE
          title={this.state.dialogTitle}
          video1={this.state.dialogVideo}
          video1_desc={this.state.dialogDesc}
          open={this.state.open}
          onClick={this.handleClick}
          onClose={this.handleClose}
          previous={this.state.previous}
          next={this.state.next}
          tablesDefinitions={this.state.tablesDefinitions}
          orderedList={this.state.orderedList}
          isMobile={this.props.isMobile}
        />
      </Box>
    );
  }
}

ContestSectionPE.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContestSectionPE);
