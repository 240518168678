import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { styled, withTheme, withStyles } from '@material-ui/core/styles'
import { Star } from '@material-ui/icons'


const MyStar = styled(Star)({
  width: 16,
  margin: "-8px 0px",
})


const styles = theme => ({
  cat: {
    fontSize: 16,
    fontWeight: "bold",
  },
});


class Category extends React.Component {

  render() {
    const { classes } = this.props

    let cat = this.props.cat
    if ('1' === cat) {
      cat = <MyStar />
    } else if ('2' === cat) {
      cat = <span><MyStar/><MyStar/></span>
    } else if ('3' === cat) {
      cat = <span><MyStar /><MyStar /><MyStar /></span>
    } else if ('V' === cat) {
      cat = 'Virtual'
    } else if ('A' === cat) {
      cat = <span>A <MyStar/><MyStar/><MyStar/><MyStar/></span>
    } else if ('B' === cat) {
      cat = <span>B <MyStar/><MyStar/><MyStar/></span>
    } else if ('C' === cat) {
      cat = <span>C <MyStar/><MyStar/></span>
    } else if ('D' === cat) {
      cat = <span>SICAB <MyStar/><MyStar/><MyStar/><MyStar/><MyStar/></span>
    } else if ('S' === cat) {
      cat = 'CTO. DEL MUNDO'
    } else if ('N' === cat) {
      cat = 'Nacional'
    } else if ('R' === cat) {
      cat = 'Regional'
    } else if ('E' === cat) {
      cat = 'Concurso Clasificatorio ANCCE'
    }

    return (
      <Box>
        <Typography className={classes.cat} color="secondary">{cat}</Typography>
      </Box>
    )
  }
}

Category.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(Category))
