import React from 'react'
import PropTypes from 'prop-types'

import { Box,
         Dialog, DialogContent, IconButton,
         CardActionArea,
         Table, TableBody, TableRow, TableCell,
         Toolbar,
         Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

import { loadImage } from 'utils/utils.js'


const styles = theme => ({
  cardng: {
    width: "100%",
    margin: 0,
    backgroundColor: "#ffffff",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      margin: "8px 0px",
    }
  },
  boxImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    margin: "auto",
    padding: 0,
  },
  cell: {
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px black",
  },
  boxInfo: {
    backgroundColor: "#00000080",
    position: "absolute",
    width: 420,
    color: "white",
    bottom: 0,
    padding: "8px 0px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },

  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "white",
    margin: 4,
    padding: 4,
    borderRadius: 0,
    zIndex: 1000,
    backgroundColor: "black"
  },
  content: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    padding: 0,
  },
  imgPopup: {
    margin: "0px 8px",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
});


class ParticipantImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleDialog = (e) => {
    e.stopPropagation()
    this.setState({open: !this.state.open})
  }

  handleClose = (e) => {
    this.setState({open: false})
  }

  getScore = (score) => {
    if (score) {
      return score.toFixed(3)
    }
  }

  render() {
    const { classes, participant, img, alt } = this.props

    return (
      <React.Fragment>
        <CardActionArea className={`${classes.cardng} ${this.props.className}`} onClick={this.handleDialog}>
          <Box className={classes.boxImage}>
            <img className={classes.img} alt={alt} src={loadImage(img)} />
            { participant &&
              <Box className={classes.boxInfo}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <Typography variant="h6">
                          POS: {participant.pos}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        <Typography variant="h6">
                          {participant.year}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell} align="right">
                        <Typography variant="h6">
                          {this.getScore(participant.scores.total)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography className={classes.cell} variant="body2" noWrap={true}>
                  {participant.contest.name}
                </Typography>
              </Box>
            }
            </Box>
        </CardActionArea>

        <Dialog className={classes.dialog}
                onClose={this.handleClose}
                onClick={this.handleDialog}
                fullScreen={true}
                /*scroll={this.props.scroll}*/
                open={this.state.open}>
          <Toolbar>
            <IconButton className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <DialogContent className={classes.content}>
            <img className={classes.imgPopup} src={loadImage(img)} alt={alt} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

ParticipantImage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantImage)

