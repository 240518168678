import React from 'react'
import PropTypes from 'prop-types'

import { Box,
         Grid,
         Divider,
         Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { MONTHS } from 'constants/constants.js'


const styles = theme => ({
  box: {
    margin: 0,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  divider: {
    height: 2,
    width: 32,
    background: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "center",
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  item: {
    margin: theme.spacing(1),
  },
  resultText: {
    listStyleType: "none",
  },
});


class JudgeCard extends React.Component {

  render() {
    const { classes, judge, amounts } = this.props

    return (
      <Box className={classes.box}>
        <Divider className={classes.divider} />
        <Typography variant="h3" align="left">{judge.name}</Typography>
        <Box className={classes.container}>
          <Grid className={classes.item}>
            <Typography className={classes.resultText} variant="h6">
              Resultados de la búsqueda
            </Typography>
            <Typography className={classes.resultText} variant="body1">
              {!this.props.search['ejname'] &&
               !this.props.search['ejcode'] &&
               !this.props.search['name'] &&
               !this.props.search['contestcode'] &&
               !this.props.search['month'] &&
               !this.props.search['year'] &&
                <li>
                  Todos
                </li>
              }
              {this.props.search['ejname'] &&
                <li>
                  Ejemplar: {this.props.search['ejname']}
                </li>
              }
              {this.props.search['ejcode'] && this.props.firstParticipant.name &&
                <li>
                  Ejemplar: {this.props.firstParticipant.name}
                </li>
              }
              {this.props.search['name'] &&
                <li>
                  Concurso: {this.props.search['name']}
                </li>
              }
              {this.props.search['contestcode'] && this.props.firstParticipant.contest &&
                <li>
                  Concurso: {this.props.firstParticipant.contest.name}
                </li>
              }
              {this.props.search['month'] &&
                <li>
                  Mes: {MONTHS[this.props.search['month']]}
                </li>
              }
              {this.props.search['year'] &&
                <li>
                  Año: {this.props.search['year']}
                </li>
              }
            </Typography>
          </Grid>

          <Grid className={classes.item}>
            <Typography variant="h6">Ejemplares juzgados</Typography>
            <Typography variant="body1">{amounts.participants}</Typography>
          </Grid>

          <Grid className={classes.item}>
            <Typography variant="h6">Concursos juzgados</Typography>
            <Typography variant="body1">{amounts.contests}</Typography>
          </Grid>
        </Box>
      </Box>
    );
  }
}

JudgeCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(JudgeCard)
