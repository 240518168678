import React from "react";

import API from "services/Api.js";
import Plyr from "plyr-react";
import "../../../styles/plyr.css";
import { withTheme, withStyles } from '@material-ui/core/styles'

import playbutton from "assets/utils/playbutton.png";
import { loadVideo } from "utils/video.js";


const styles = theme => ({
  videoContainer: {
    position: "relative",
  },
  liveVideo: {
    flex: "1 1 auto",
    maxWidth: "100%",
    aspectRatio: "16/9",
    border: "none",
    [theme.breakpoints.up('md')]: {
      minWidth: "448px",
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      gap: theme.spacing(1),
    },
  },
  posterOverlay: {
    width: "100%",
    height: " 100%",
    zIndex: 1,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    height: "50px",
  },
});


class Video extends React.PureComponent {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.adsplayer = React.createRef();
    this.state = {
      hasAds: undefined,
    };
    this.videoData = loadVideo(this.props.source);
  }

  checkVideoAdsPolicy = () => {
    API.videos.getAds(this.props.source).then((res) => {
      this.setState(
        {
          ...this.state,
          hasAds: res.ads !== null,
          sourceAd: res.ads ? loadVideo(res.ads) : null,
        },
        () => {
          setTimeout(() => {
            if (this.state.hasAds) {
              this.playVideoAfterAdEnds();
              return;
            }
            this.player.current.plyr.play();
          }, 50);
        }
      );
    });
  };

  playVideoAfterAdEnds() {
    this.adsplayer.current.plyr.on("ended", () => {
      this.setState(
        {
          ...this.state,
          hasAds: false,
          adPlayed: true,
        },
        () => setTimeout(() => this.player.current.plyr.play(), 50)
      );
    });
  }

  render() {
    const { classes } = this.props

    return (
      <>
        {this.props.source && (
          <div className={classes.videoContainer}>
            {this.state.hasAds === undefined && (
              <div className={classes.liveVideo}>
                <div className={classes.posterOverlay} onClick={this.checkVideoAdsPolicy}>
                  <img className={classes.button} src={playbutton} alt="play"></img>
                </div>
                { this.videoData.type === "iframe" ? (
                    <iframe className={classes.liveVideo} src={this.videoData.src} allowFullScreen>
                    </iframe>
                  ) : (
                    <div className={classes.liveVideo}>
                      <Plyr
                        options={{ controls: [] }}
                        source={loadVideo(this.props.source)}
                      />
                    </div>
                  )
                }
              </div>
            )}
            {this.state.hasAds === true && (
              <div className={classes.liveVideo}>
                <Plyr
                  options={{
                    controls: [],
                  }}
                  autoPlay={true}
                  key="ad"
                  ref={(player) => (this.adsplayer.current = player)}
                  source={this.state.sourceAd}
                />
              </div>
            )}

            {this.state.hasAds === false && this.videoData.type === "iframe" &&
              <iframe className={classes.liveVideo} src={this.videoData.src} allowFullScreen>
              </iframe>
            }

            {this.state.hasAds === false && this.videoData.type !== "iframe" &&
              <div className={classes.liveVideo}>
                <Plyr
                  key="video"
                  source={loadVideo(this.props.source)}
                  ref={(player) => (this.player.current = player)}
                />
              </div>
            }
          </div>
        )}
      </>
    );
  }
}

export default withTheme(withStyles(styles)(Video))
