import React from 'react'
import PropTypes from 'prop-types'

import { Grid,
         Divider,
         Typography,
         Avatar} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  gridParent: {
    margin: 0,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  subGridParent: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  subGridItem: {
    marginBottom: theme.spacing(1),
  },
  subGridImage: {
    flexGrow: 0,
    paddingRight: theme.spacing(1),
  },
  gridInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      paddingTop: theme.spacing(2),
    }
  },
  mainImg: {
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  divider: {
    height: 2,
    width: 32,
    background: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
});


class ParticipantCard extends React.Component {

  render() {
    const { classes, participant, img } = this.props

    return (
      <Grid className={classes.gridParent} container>
        <Grid item xs={12} sm={4}>
          <img className={classes.mainImg} src={img} alt={"ejemplar " + participant.name} />
        </Grid>
        <Grid className={classes.gridInfo} item xs={12} sm={8}>
          <Divider className={classes.divider} />
          <Typography variant="h3" align="left">{participant.name}</Typography>
          <Grid className={classes.subGridParent} container>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Grid container>
                <Grid item xs className={classes.subGridImage}>
                  <Avatar variant="square" src={participant.breeder_iron} alt={"ganadería criadora " + participant.breeder_name} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">Ganadería criadora</Typography>
                  <Typography variant="body1">{participant.breeder_name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Grid container justify="flex-start">
                <Grid item xs className={classes.subGridImage}>
                  <Avatar variant="square" src={participant.livestock_holder_iron} alt={"ganadería titular " + participant.livestock_holder_name} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">Ganadería titular</Typography>
                  <Typography variant="body1">{participant.livestock_holder_name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Typography variant="h6">Fecha de nacimiento</Typography>
              <Typography variant="body1">{participant.date_birth}</Typography>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Typography variant="h6">Código LG</Typography>
              <Typography variant="body1">{participant.code}</Typography>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Typography variant="h6">Sexo</Typography>
              <Typography variant="body1">{ participant.gender || '-' }</Typography>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Typography variant="h6">Capa</Typography>
              <Typography variant="body1">{ participant.genetic_coat_name || participant.genetic_datebirth_name || '-' }</Typography>
            </Grid>
            <Grid className={classes.subGridItem} item xs={12} sm={6}>
              <Typography variant="h6">Actualizado</Typography>
              <Typography variant="body1">{ participant.updated }</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ParticipantCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantCard)
