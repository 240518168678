import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { SPECIAL_AWARD_TYPES } from 'constants/constants.js'


const styles = theme => ({
  cellTitle: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  cell: {
    padding: 4,
  },
  table: {
    width: "auto",
  }
});


class SpecialAwardTable extends React.Component {

  extract_judges = () => {
    let participant_numbers = Object.keys(this.props.scores)
    let judge_scores = this.props.scores[participant_numbers[0]].judge_scores
    return Object.entries(judge_scores).sort().map(([alias, data]) => data.judge)
  }

  render() {
    const { classes } = this.props
    const judges = this.extract_judges()
    const table_title = SPECIAL_AWARD_TYPES[this.props.score_type]

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellTitle} align="center" colSpan={ judges.length + 1}>
              { table_title }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            { judges.map((judge, key) => {
              return (
                <TableCell key={key} className={classes.cell} align="center">
                  { judge.alias } { judge.quality_vote && ' (VC)' }
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {Object.entries(this.props.scores).map(([number, data], key) => {
            return (
              <TableRow key={key}>
                <TableCell className={classes.cell} align="right">
                  <b>{number}</b>
                </TableCell>
                { judges.map((judge, key) => {
                  return (
                    <TableCell key={key} className={classes.cell} align="center">
                      { data.judge_scores[judge.alias].score }
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    );
  }
}

SpecialAwardTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SpecialAwardTable)
