import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { Box,
         Table, TableBody, TableCell, TableHead, TableRow,
         Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  box: {
    borderBottom: "none",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  table: {
    margin: 0,
    marginTop: theme.spacing(2),
    width: "auto",
  },
  cellParticipant: {
    textDecoration: "underline",
    color: "black",
  },
});


class ParticipantSelect extends React.Component {

  render() {
    const { classes, availables } = this.props

    return (
      <Box className={classes.box}>
        <Typography variant="h4">
          Se han encontrado {availables.length} participantes que coinciden con la búsqueda.
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Nombre</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Código LG</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availables.map((participant, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography className={classes.cellParticipant} component={Link} to={`/participant/?code=${participant.code}`}>
                      {participant.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{participant.code}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

ParticipantSelect.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantSelect)
