import React from "react";
import PropTypes from "prop-types";

import { Button, ButtonGroup, MenuItem, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { withTheme, withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  btnGroupSections: {
    display: "block",
    textAlign: "left",
    borderRadius: 0,
    fontWeight: "bold",
  },
  btnSection: {
    borderRadius: 0,
  },
  btnSectionSelected: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    },
  },
  btnSectionMobile: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    border: 0,
    borderRadius: 0,
    height: 48,
    color: "white",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
});

class Sections extends React.PureComponent {
  getSection = (id, name) => {
    let res = "";
    if (this.props.discipline === "morf") {
      if (id !== "pe") {
        res = "S" + id;
      } else {
        res = id;
      }
    } else if (this.props.discipline === "doma") {
      res = name;
      if (id === "mg") {
        res = "Mejor Ganadería";
      }
    } else {
      res = name;
    }
    return res;
  };

  render() {
    const { classes } = this.props;

    if (!this.props.sections) {
      return null;
    }

    return this.props.isMobile ? (
      <Select
        className={classes.btnSectionMobile}
        onChange={this.props.changeSelectSection}
        value={this.props.section}
        displayEmpty
        disableUnderline={true}
        IconComponent={ExpandMore}
        autoWidth={false}
      >
        <MenuItem disabled key={0} value="">
          {this.props.title}
        </MenuItem>
        {Object.entries(this.props.sections).map((s, i) => {
          return (
            <MenuItem key={i} value={s[0]}>
              {s[1]}
            </MenuItem>
          );
        })}
      </Select>
    ) : (
      <ButtonGroup className={classes.btnGroupSections} color="secondary">
        {Object.entries(this.props.sections).map((s, i) => {
          let name = this.getSection(s[0], s[1]);

          let cn = classes.btnSection;
          if (s[0] === this.props.section) {
            cn = classes.btnSectionSelected;
          }
          return (
            <Button
              key={i}
              className={cn}
              onClick={() => this.props.changeButtonGroupSection(s[0])}
            >
              {name}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

Sections.propTypes = {
  classes: PropTypes.object.isRequired,
};
Sections.defaultProps = {
  title: "Seleccione una sección",
};

export default withTheme(withStyles(styles)(Sections));
