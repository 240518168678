import React from 'react'
import PropTypes from 'prop-types'

import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import header1x from 'assets/header/header.png'
import Headerx from 'assets/header/header@2x.png'
import header3x from 'assets/header/header@3x.png'
import logo from 'assets/header/logo.svg'


const styles = theme => ({
  logo: {
    width: 160,
    height: "auto",
    margin: 32,
    paddingTop: 50,
    [theme.breakpoints.up('md')]: {
      width: 200,
      margin: 22,
    },
  },
  divider: {
    height: 16,
    background: theme.palette.primary.main
  },
  paper: {
    height: 400,
    backgroundImage: 'url(' + header1x + ')',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'url(' + Headerx + ')',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: 'url(' + header3x + ')',
    },
    flexWrap: "nowrap",
    //opacity: 0.2,
  },
  info: {
    marginLeft: theme.spacing(22),
    marginRight: theme.spacing(22),
    color: "white",
    textShadow: "0 2px 2px rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
});


class HomeHeader extends React.Component {
  render() {
    const { classes, info, mb } = this.props
    return <Box mb={mb}>
      <Divider className={classes.divider} />
      <Grid className={classes.paper} container direction="column" justify="space-between" alignItems="center">
        <img className={classes.logo} src={logo} alt="logo" />
        <Box className={classes.info} mb={8}>
          <Typography variant="h4">{ info }</Typography>
        </Box>
      </Grid>
    </Box>
  }
}

HomeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
}

HomeHeader.defaultProps = {
  info: null,
  mb: 2,
}

export default withStyles(styles)(HomeHeader)
