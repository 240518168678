import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableHead, TableRow,
         Typography } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'

import API from 'services/Api.js'
import Loader from 'components/ui/Loader'


const styles = theme => ({
  waiting: {
    marginTop: '5%',
    marginLeft: '10%',
    display: "block",
    [theme.breakpoints.down('sm')]: {
      display: "inline-block",
    },
  },
  table: {
    width: "auto",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      margin: "auto",
      tableLayout: "fixed"
    },
  },
  row: {
    backgroundColor: "#f1f1f1",
    height: 48,
    border: "1px solid white",
    whiteSpace: "break-spaces",
  },
  subtitle: {
    textAlign: "left",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    }
  },
});


class ContestTimetable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      timetable: {
        headers: [],
        events: [],
      },
      searching: true,
    }
  }

  componentDidMount() {
    API.contest.getSchedule(this.props.contest_code)
      .then(resp => {
        this.setState({timetable: resp, searching: false})
      })
      .catch(error => {
        this.setState({searching: false})
        console.log("Error loading timetable: " + this.props.contest_code);
      });
  }

  render() {
    const { classes } = this.props

    if (this.state.searching) {      
      return <Loader class={classes.waiting} />
    }

    if (this.state.timetable.events.length === 0) {
      return (
        <Typography className={classes.subtitle} variant="subtitle1">
          El horario no está publicado
        </Typography>
      )
    }

    return (
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
          { this.state.timetable.headers.map((th, key) =>
              <TableCell key={ key }>{ th }</TableCell>
          )}
          </TableRow>
        </TableHead>
        <TableBody>
          { this.state.timetable.events.map((event, key) => {
            return (
              <TableRow key={key} className={classes.row}>
                <TableCell>
                  <b>{ this.props.isMobile ? event.short_name : event.name }</b>
                </TableCell>
                { event.proofs.map((proof, key2) =>
                  <TableCell key={ key2 }>
                    { proof.timedate }
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    );
  }
}

ContestTimetable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(ContestTimetable))
