import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Box, CardActionArea, Typography } from '@material-ui/core'
import { Link } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Category from 'utils/Category.js'


const styles = theme => ({
  cardng: {
    width: 300,
    margin: theme.spacing(2),
    backgroundColor: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      width: 280,
      margin: theme.spacing(1),
    }
  },
  cardLive: {
    border: "2px solid #c91c36",
  },
  cardSoon: {
    border: "2px solid #fab31e",
  },
  boxImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
  },
  img: {
    width: "300px",
    height: "420px",
    margin: "auto",
    padding: 0,
    objectFit: 'contain',
    backgroundColor: 'gainsboro',
    [theme.breakpoints.down('xs')]: {
      width: 280,
      height: 392,
    }
  },
  boxInfo: {
    textAlign: "left",
  },
  boxTitle: {
    //textTransform: "capitalize"
  },
  textCity: {
    fontWeight: "bold",
  },
  textDate: {
    marginBottom: 8
  },
});


class ContestCard extends React.Component {
  render() {
    const { classes } = this.props

    let border = [classes.cardpng];
    const card_classes = clsx(classes.cardng, {
      [classes.cardSoon]: this.props.border === 'soon',
      [classes.cardLive]: this.props.border === 'live',
    })

    return (
      <CardActionArea className={card_classes} component={Link} to={`/contests/${this.props.discipline}/${this.props.code}`}>
        <Box className={classes.boxImage} mb={1}>
          <img className={classes.img} src={this.props.img} alt="logo" />
        </Box>
        <Box className={classes.boxInfo} px={1}>
            <Category cat={this.props.cat} />
            <Box className={classes.boxTitle} mb={2}>
              <Typography variant="h5">{this.props.name}</Typography>
            </Box>
            <Typography className={classes.textCity} variant="body2" noWrap={true}>{this.props.city}</Typography>
            <Typography variant="body2" paragraph={true}>{`${this.props.start} - ${this.props.end}`}</Typography>
        </Box>
      </CardActionArea>
    )
  }
}

ContestCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContestCard)
