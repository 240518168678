import React from 'react'
import PropTypes from 'prop-types'

import { Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { Link } from "react-router-dom"

import API from 'services/Api.js'
import { DOMA_LEVELS } from 'constants/constants.js'


const styles = theme => ({
  box: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    margin: "0px 0px",
  },
  table: {
    width: "100%",
    marginTop: 32
  },
  link: {
    color: "black",
    textDecoration: "underline",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  header: {
    height: 32,
    backgroundColor: "black",
    textTransform: "uppercase",
  },
  headerPos: {
    color: "white",
    fontWeight: "bold",
  },
  headerScore: {
    color: "white",
    fontWeight: "bold",
    textAlign: "right",
    paddingRight: 8,
  },
  rowParticipantBody: {
    backgroundColor: "#f1f1f1"
  },
  rowWhite: {
    backgroundColor: "white",
    height: 1,
  },
  cellParticipant: {
    padding: "10px 8px",
  },
  cellScores: {
    padding: "10px 8px",
    textAlign: "right"
  },
});


class ContestLevelBestBreederMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      breeders: [],
      searching: false
    }
  }

  componentDidMount() {
    this.setState({searching: true})
    API.dressage.bestBreeder(this.props.contest_code)
      .then(resp => {
        this.setState({breeders: resp.breeders, searching: false})
      })
      .catch(error => {
        console.error("Error loading breeders: " + this.props.contest_code)
        this.setState({searching: true})
      });
  }

  render() {
    const { classes } = this.props

    return (
      <Box className={classes.box}>

        <Table className={classes.table}>
          <TableBody>

            { this.state.breeders.map((breeder, key) => {
              // Breeder header
              return (
                <React.Fragment key={key}>
                  <TableRow className={classes.rowWhite}></TableRow>
                  <TableRow className={classes.header}>
                    <TableCell className={classes.headerPos}>
                      { key + 1 }º  { breeder.name }
                    </TableCell>
                    <TableCell className={classes.headerScore}>
                      { breeder.scores }
                    </TableCell>
                  </TableRow>

                  { breeder.participants.map((participant, keyp) => {
                    // Participants table body
                    return (
                      <React.Fragment key={keyp}>
                        <TableRow className={classes.rowParticipantBody}>
                          <TableCell className={classes.cellParticipant}>
                            <Link className={classes.link} to={ "/participant?code=" + participant.code }>
                              { participant.name}
                            </Link>
                            <br/>
                            { DOMA_LEVELS[participant.level] }
                            <br/>
                            { participant.livestock }
                            <br/>
                            J: { participant.rider }
                          </TableCell>
                          <TableCell className={classes.cellScores}>
                            P1: <b> { participant.p1 } ({ participant.p1_comp }) </b> <br/>
                            P1 punt: <b> { participant.p1_punt } </b> <br/>
                            P2: <b> { participant.p2 } ({ participant.p2_comp }) </b> <br/>
                            P2 punt: <b> { participant.p2_punt } </b> <br/>
                            Total: <b> { participant.total } ({ participant.total_comp }) </b> <br/>
                            <b> PUNTOS: { participant.pmg_total }</b>
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.rowWhite}>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

ContestLevelBestBreederMobile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContestLevelBestBreederMobile)
