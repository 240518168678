import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'

import API from './Api.js'


const styles = theme => ({
  adsContainer: {
    position: "fixed",
    bottom: 0,
    zIndex: 99,
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up('lg')]: {
      position: "absolute",
      display: "block",
      width: "auto",
      marginLeft: 50,
      marginTop: 60,
      bottom: "unset",
    },
  },
});


function shuffle(array: Array): Array {
  var currentIndex = array.length, temporaryValue, randomIndex
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}


class Banner {
  img: String
  link: String

  constructor(serializer: Object) {
    this.img = serializer.img
    this.link = serializer.link
  }
}


class Advertisement {
  _width: Number
  _height: Number
  _timeout: Number
  _sequential: Boolean
  _banners: Banner[]

  constructor(config: Object, banners: Object[]) {
    this._width = config.width
    this._height = config.height
    this._timeout = config.timeout
    this._sequential = config.seq
    this._banners = banners.map(banner_serializer => new Banner(banner_serializer))

    if (!this.sequential) {
      this._banners = shuffle(this._banners)
    }

    this._index = 0
    this.MAX_INDEX = this._banners.length
  }

  isValid(): boolean {
    return this._width && this._height && this._banners.length > 0
  }

  getTimeout(): Number {
    // Convert seconds to miliseconds
    return this._timeout * 1000
  }

  getStyles(): Object {
    return {
      width: this._width,
      height: this._height,
    }
  }

  getNextBanner(): Banner {
    let currentBanner = this._banners[this._index]
    this._index = (this._index + 1) % this.MAX_INDEX
    return currentBanner
  }
}


class Ads extends React.PureComponent {
  constructor(props) {
    super(props)
    this._is_mounted = false
    this.ads = null
    this._interval = null

    this.state = {
      interval: null,
      currentBanner: null
    }
  }

  componentDidMount() {
    this._is_mounted = true
    API.ads.get()
      .then(resp => {
        this.ads = new Advertisement(resp, resp.banners)
        let adsTimeout = this.ads.getTimeout()
        this.setState({
          interval: adsTimeout > 0 ? setInterval(this.changeBanner.bind(this), adsTimeout) : null,
          currentBanner: this.ads.getNextBanner()
        })
      })
      .catch(error => {
        console.error("Error loading advertisement: " + error)
      });
  }

  changeBanner = () => {
    this.setState({currentBanner: this.ads.getNextBanner()})
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearInterval(this._interval)
  }

  render() {
    const { classes } = this.props

    return (
      <Box className={classes.adsContainer}>
        { this.ads &&
          <Box>
            <a target="_blank" rel="noopener noreferrer" href={this.state.currentBanner.link}>
              <img style={this.ads.getStyles()} alt="ads" src={this.state.currentBanner.img} />
            </a>
          </Box>
        }
      </Box>

    );
  }
}

Ads.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(Ads))
