import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
} from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/core/styles';

import API from 'services/Api.js';
import RowClickAndExpand from 'components/pages/contest/RowClickAndExpand.js';
import { DOMA_LEVELS } from 'constants/constants.js';
import Sections from 'components/pages/contest/Sections.js';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from 'components/ui/Loader';

const styles = (theme) => ({
  waiting: {
    marginTop: '5%',
    marginLeft: '10%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  table: {
    width: 'auto',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  row: {
    backgroundColor: '#f1f1f1',
    height: 48,
    borderBottom: '1px solid white',
  },
  cellLivestock: {
    maxWidth: 250,
  },
  cellPMG: {
    width: 30,
    textAlign: 'right',
    margin: 0,
    padding: '0px 5px 0px 0px',
  },
  cellNumber: {
    width: 30,
    textAlign: 'right',
    margin: 0,
    padding: '0px 5px 0px 0px',
  },
  cellNumberDoma: {
    width: 100,
    textAlign: 'right',
    margin: 0,
    padding: '0px 5px 0px 0px',
  },
  cellHorse: {
    textAlign: 'left',
    margin: 0,
    padding: 0,
  },
  cellCobra: {
    textAlign: 'left',
    margin: 0,
    padding: '8px 0px',
  },
  subtitle: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  link: {
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'underline',
    padding: 0,
    whiteSpace: 'break-spaces',
    lineHeight: 1.5,
  },
  search: {
    marginTop: theme.spacing(2),
    display: 'block',
    textAlign: 'left',
    width: 290,
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      display: 'inline-flex',
      margin: '8px 0px',
    },
  },
  rotate0: {
    color: 'white',
    transform: 'rotate(0deg)',
  },
  rotate180: {
    color: theme.palette.primary.main,
    transform: 'rotate(180deg)',
  },
  tableRowParticipant: {
    backgroundColor: '#f1f1f1',
    height: 48,
  },
  cellPos: {
    fontWeight: 'bold',
    backgroundColor: 'black',
    color: 'white',
    padding: 0,
    width: 32,
  },
  cellCollapse: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  cellCollapseActive: {
    borderBottom: '4px solid ' + theme.palette.primary.main,
    padding: 0,
    width: 32,
  },
  table: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  strikethrough: {
    /*textDecoration: 'line-through',*/
    background: 'repeating-linear-gradient(180deg, black 0%, black 100%)',
    backgroundSize: '100% 1px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }
});

class ContestLivestock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      livestocks: [],
      livestocksScore: [],
      breederScore: [],
      searching: true,
      search_livestock: '',
      expanded: [],
    };
  }

  componentDidMount() {
    Promise.all([
      API.contest.getLivestocks(this.props.contest_code, this.props.discipline),
      API.contest.getBreederRank(this.props.contest_code, this.props.discipline),
      API.contest.getLivestockRank(this.props.contest_code, this.props.discipline),
    ])
      .then(([livestocks, livestocksScore, breederScore]) => {
        this.setState({
          livestocks,
          livestocksScore,
          breederScore,
          searching: false,
        });
      })
      .catch((error) => {
        this.setState({ searching: false });
        console.log('Error loading livestocks: ' + this.props.contest_code);
      });
  }

  handleSearch = (event) => {
    this.setState({ search_livestock: event.target.value.toUpperCase() });
  };

  handleExpandClick = (pos) => {
    this.state.expanded[pos] = !this.state.expanded[pos];
    this.setState({ expanded: [...this.state.expanded] });
  };

  getPMX = (participants) => {
    // All participants and cobra check that pmg = true, are the same breeder
    // else, instead PMG, will be PME
    // Livestock cannot participate if not present participants and cobras

    let breeders = [];
    if (participants.morf) {
      participants.morf.filter((p) => p.pmg).forEach((h) => {
        breeders.push(h.breeder);
      })
    }
    if (participants.cobras) {
      participants.cobras
        .filter((p) => p.pmg)
        .forEach((p) => {
          breeders.push(...p.horses.map((h) => h.breeder));
        });
    }

    return new Set(breeders).size === 1 ? 'PMG' : 'PME';
  }

  render() {
    const { classes } = this.props;
    if (this.state.searching) {
      return <Loader class={classes.waiting} />
    }

    if (this.state.livestocks.length === 0) {
      return (
        <Typography className={classes.subtitle} variant="subtitle1">
          Las ganaderías no están publicadas
        </Typography>
      );
    }

    let headers = null;
    if (this.props.discipline === 'morf') {
      headers = (
        <TableRow>
          <TableCell align="center">GANADERÍAS</TableCell>
          <TableCell align="center">EJEMPLARES</TableCell>
          <TableCell align="center">COBRAS</TableCell>
        </TableRow>
      );
    } else if (this.props.discipline === 'doma') {
      headers = (
        <TableRow>
          <TableCell align="center">GANADERÍAS</TableCell>
          <TableCell align="center">EJEMPLARES</TableCell>
        </TableRow>
      );
    }

    return (
      <React.Fragment>
        {this.props.discipline === 'morf' && (
          <Sections
            isMobile={this.props.isMobile}
            sections={[
              ['Participantes'],
              ['Ptos. Criadora'],
              ['Ptos. Expositora'],
            ]}
            title='Seleccione una opción'
            section={this.props.section}
            changeSelectSection={this.props.changeSelectSection}
            changeButtonGroupSection={this.props.changeButtonGroupSection}
          />
        )}

        {(this.props.discipline !== 'morf' || this.props.section === '0') &&
          this.getGanaderiasSection(classes)}
        {(this.props.discipline === 'morf' && this.props.section === '1') &&
          this.getBreederScoreSection(classes)}
        {(this.props.discipline === 'morf' && this.props.section === '2') &&
          this.getLivestockScoreSection(classes)}
      </React.Fragment>
    );
  }

  getBreederScoreSection(classes) {
    return this.getRankSection(classes, this.state.livestocksScore);
  }

  getLivestockScoreSection(classes) {
    return this.getRankSection(classes, this.state.breederScore);
  }

  getRankSection(classes, list) {
    const iconRotate = (i) =>
      clsx(classes.rotate0, {
        [classes.rotate180]: this.state.expanded[i],
      });
    const cellExpand = clsx(classes.cellMobileExpand, {
      [classes.cellMobileExpandOpen]: this.state.expanded,
    });

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>POS.</TableCell>
            <TableCell className={classes.tableHeader}>GANADERÍA</TableCell>
            <TableCell className={classes.tableHeader}>PUNTUACIÓN</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {list.map((livestock, key) => (
            <React.Fragment key={key}>
              <TableRow
                className={classes.tableRowParticipant}
                onClick={() => this.handleExpandClick(key)}
              >
                <TableCell className={classes.cellPos} align="center">
                  <Typography className={classes.cellJumpLine} variant="body2">
                    {livestock.pos}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.cellJumpLine} variant="body2">
                    {livestock.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.cellJumpLine} variant="body2">
                    {livestock.total.toFixed(3)}
                  </Typography>
                </TableCell>
                <TableCell
                  className={
                    this.state.expanded[key]
                      ? classes.cellCollapseActive
                      : classes.cellCollapse
                  }
                  align="center"
                >
                  <ExpandMoreIcon className={iconRotate(key)} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.cellExpand} colSpan={8}>
                  <Collapse
                    className={classes.collapse}
                    in={this.state.expanded[key]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Dorsal</TableCell>
                          <TableCell>Ejemplar</TableCell>
                          <TableCell>Sección</TableCell>
                          <TableCell>Pos.</TableCell>
                          <TableCell>Punt.</TableCell>
                          <TableCell>P.E.</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {livestock.participants.map((participant) => (
                          <TableRow key={participant.number}
                                    className={participant.strikethrough ? classes.strikethrough : ''}
                          >
                            <TableCell>{participant.number}</TableCell>
                            <TableCell>
                              <Link className={classes.link} to={{
                                pathname: `/contests/morf/${this.props.contest_code}`,
                                search: `?main_section=secciones&section=${participant.section_id}`,
                                state: { fromLivestock: true }
                              }}>
                                {participant.name}
                              </Link>
                            </TableCell>
                            <TableCell>{participant.section_code}</TableCell>
                            <TableCell>{participant.pos}</TableCell>
                            <TableCell>{participant.score}</TableCell>
                            <TableCell>
                              {participant['score_special_award']}
                            </TableCell>
                            <TableCell>{participant.score_total}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    );
  }

  getGanaderiasSection(classes, headers) {
    return (
      <>
        <Typography key={1} className={classes.subtitle} variant="subtitle1">
          <b>{this.state.livestocks.length}</b> Ganaderías participantes
        </Typography>

        <TextField
          key={2}
          className={classes.search}
          value={this.state.search_livestock}
          onChange={this.handleSearch}
          label="Filtrar ganadería"
          variant="outlined"
        />

        <Table key={3} className={classes.table} size="small">
          {!this.props.isMobile && <TableHead>{headers}</TableHead>}

          <TableBody>
            {this.state.livestocks
              .filter((p) => p.livestock.includes(this.state.search_livestock))
              .map((livestock, key) => {
                let livestock_name = livestock.livestock;
                let participants = [];
                let cobras = [];
                if (this.props.discipline === 'morf') {
                  participants = livestock.participants.morf || [];
                  cobras = livestock.participants.cobras || [];
                } else if (this.props.discipline === 'doma') {
                  participants = livestock.participants.doma || [];
                } else {
                  participants = livestock.participants.od || [];
                }

                let PMX = this.getPMX(livestock.participants);

                let participantRow = participants.map((participant, key) => {
                  let number = null;
                  if (this.props.discipline === 'morf') {
                    number = (
                      <React.Fragment>
                        <TableCell className={classes.cellPMG}>
                          {participant.pmg && PMX}
                        </TableCell>
                        <TableCell className={classes.cellNumber}>
                          {participant.number}
                        </TableCell>
                      </React.Fragment>
                    );
                  } else if (this.props.discipline === 'doma') {
                    number = (
                      <TableCell className={classes.cellNumberDoma}>
                        {DOMA_LEVELS[participant.number]}
                      </TableCell>
                    );
                  } else {
                    number = (
                      <TableCell className={classes.cellNumberDoma}>
                        {participant.number}
                      </TableCell>
                    );
                  }
                  return (
                    <Table key={`p${key}`} className={classes.tableParticipant}>
                      <TableBody>
                        <TableRow>
                          {number}
                          <TableCell className={classes.cellHorse}>
                            <Link
                              className={classes.link}
                              to={'/participant?code=' + participant.code}
                            >
                              {participant.name}
                              <br />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  );
                });

                let cobraRow =
                  cobras &&
                  cobras.map((cobra, key) => {
                    return (
                      <Table key={`c${key}`}>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.cellPMG}>
                              {cobra.pmg && PMX}
                            </TableCell>
                            <TableCell className={classes.cellNumber}>
                              {cobra.number}
                            </TableCell>
                            <TableCell className={classes.cellCobra}>
                              {cobra.horses.map((horse, key_h) => {
                                return (
                                  <Link
                                    key={`x${key}-${key_h}`}
                                    className={classes.link}
                                    to={'/participant?code=' + horse.code}
                                  >
                                    {horse.name}
                                    <br />
                                  </Link>
                                );
                              })}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                  });

                let amountParticipants =
                  participants.length > 0 ? (
                    <Typography variant="body2" noWrap>
                      {participants.length}{' '}
                      {participants.length === 1 ? 'ejemplar' : 'ejemplares'}
                    </Typography>
                  ) : null;

                let amountCobras =
                  cobras.length > 0 ? (
                    <Typography variant="body2" noWrap>
                      {cobras.length} {cobras.length === 1 ? 'cobra' : 'cobras'}
                    </Typography>
                  ) : null;

                if (this.props.isMobile) {
                  return (
                    <RowClickAndExpand
                      key={key}
                      contentRow={[
                        <TableCell key={1} className={classes.cellLivestock}>
                          <b>{livestock_name}</b>
                        </TableCell>,
                        <TableCell key={2}>
                          {amountParticipants} {amountCobras}
                        </TableCell>,
                      ]}
                      contentExpandRow={[participantRow, cobraRow]}
                      numRows={2}
                    />
                  );
                } else {
                  return (
                    <TableRow key={key} className={classes.row}>
                      <TableCell className={classes.cellLivestock}>
                        <b>{livestock_name}</b>
                        <br />
                        {amountParticipants}
                        {amountCobras}
                      </TableCell>
                      <TableCell>{participantRow}</TableCell>
                      <TableCell>{cobraRow}</TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </>
    );
  }
}

ContestLivestock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(ContestLivestock));
