import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Switch, Route } from "react-router-dom"

import { Box, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ThemeProvider, withStyles } from '@material-ui/core/styles'

import theme from './styles/theme.js'
import Ads from './services/Ads.js'
import Contest from 'components/pages/contest/Contest.js'
import Home from 'components/pages/home/Home.js'
import Judge from 'components/pages/judge/Judge.js'
import ListContest from './components/pages/contest-list/ListContest.js'
import Participant from './components/pages/participant/Participant.js'
import Ranking from 'components/pages/ranking/Ranking.js'
import RankingMorf from 'components/pages/ranking/RankingMorf.js'
import RankingOD from 'components/pages/ranking/RankingOD.js'


const styles = theme => ({
  app: {
    textAlign: "center",
  },
  btnUpdate: {
    border: "1px solid green",
    padding: "2px 4px",
    marginLeft: theme.spacing(1),
    backgroundColor: "seagreen",
    color: "white",
  },
});


class App extends React.Component {
  updateAPP = () => {
    const registrationWaiting = this.props.swRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    const swConfig = {
      update: this.props.updateAvailable,
      cached: this.props.contentCached,
      reg: this.props.swRegistration
    }

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.app}>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.props.updateAvailable}>
            <Alert icon={false} severity="success" onClick={this.updateAPP}>
              Nueva versión disponible<span className={classes.btnUpdate}>Actualizar</span>
            </Alert>
          </Snackbar>
          <Ads />
          <BrowserRouter basename="/public3">
            <Switch>
              <Route path="/participant" component={Participant} />
              <Route path="/judge" component={Judge} />
              <Route path="/contests/:discipline/:id" component={Contest} />
              <Route path="/contests/:discipline" component={ListContest} />
              <Route path="/doma/ranking" component={Ranking} />
              <Route path="/morf/ranking" component={RankingMorf} />
              <Route path="/:discipline/ranking" component={RankingOD} />
              <Route path="/" render={(props) => <Home {...props} swConfig={swConfig} />} />
            </Switch>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    );
  }
}


App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App)
