import { withStyles } from "@material-ui/core";
import React, { Component } from "react";

const styles = (theme) => ({});

class Spinner extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div></div>;
  }
}

export default withStyles(styles)(Spinner);
