export class ParticipantStatus {
  static QUALIFIED = new ParticipantStatus("C", "");
  static ELIMINATED = new ParticipantStatus("elim.", "elim.");
  static ELIMINATED_MORPHO = new ParticipantStatus("elim. morf", "elim.");
  static ELIMINATED_FUNC = new ParticipantStatus("elim. func", "elim.");
  static RETIRED = new ParticipantStatus("ret.", "ret.");
  static RETIRED_MORPHO = new ParticipantStatus("ret. morf", "ret.");
  static RETIRED_FUNC = new ParticipantStatus("ret. func", "ret.");
  static NO_SHOW = new ParticipantStatus("NP", "NP");

  constructor(name, desc) {
    this.name = name;
    this.desc = desc;
  }

  static is_eliminated(participant_status) {
    return [
      ParticipantStatus.ELIMINATED.name,
      ParticipantStatus.RETIRED.name,
      ParticipantStatus.NO_SHOW.name,
    ].includes(participant_status);
  }
}

export const loadImage = (img) => {
  if (process.env.NODE_ENV === 'development') {
    return "https://www.concursosancce.com/static/media/" + img
  }
  return process.env.REACT_APP_MEDIA_URL + img
}


export const getScoreDoma = (state, score) => {
  if (state === 'P' && score !== null && score > 0) {
    return score.toFixed(3)
  } else if (state === 'N') {
    return 'NO INS.'
  } else if (state === 'R') {
    return 'RET.'
  } else if (state === 'E') {
    return 'ELIM.'
  } else if (state === 'J') {
    return 'NP-JUS.'
  } else if (state === 'X') {
    return 'NP.'
  } else if (state === 'K') {
    return 'KÜR'
  } else {
    return '-'
  }
}


export const getPositionDoma = (pos, state_p1, state_p2) => {
  if (state_p1 !== 'P' && state_p2 !== 'P') {
    return '-'
  } else {
    return pos
  }
}


export const getTotalDoma = (score) => {
  if (!score || score.state_p2 === 'X' || (score.state_p1 !== 'P' && score.state_p2 !== 'P')) {
    return 'N/C'
  } else {
    return `${score.total.toFixed(3)}%`
  }
}


export const getPosition = (pos, scores, type) => {
  let res = pos
  if (type === 'morf') {
    if (ParticipantStatus.is_eliminated(scores.status) || scores.semi) {
      res = '-'
    } else if (!scores.total) {
      res = '-'
    }
  } else if (type === 'doma') {
    // PENDING save pos_final
  }
  return res
}


export const getScores = (scores, type, score_types=null) => {
  let result_scores = {}

  if (type === 'morf') {
    let score_order = score_types || ['total', 'morf', 'mov', 'func']
    score_order.forEach(score_type => {
      let res = scores[score_type]
      if (res > 0) {
        res = res.toFixed(3)
      }

      if (score_type === 'func' && scores.status === ParticipantStatus.ELIMINATED_FUNC.name) {
        res = 'elim.'
      } else if (score_type === 'func' && scores.status === ParticipantStatus.RETIRED_FUNC.name) {
        res = 'ret.'
      } else if (['morf', 'mov'].includes(score_type) && scores.status === ParticipantStatus.ELIMINATED_MORPHO.name) {
        res = 'elim.'
      } else if (['morf', 'mov'].includes(score_type) && scores.status === ParticipantStatus.RETIRED_MORPHO.name) {
        res = 'ret.'
      } else if (['morf', 'mov', 'func'].includes(score_type) && (ParticipantStatus.is_eliminated(scores.status) || scores.semi)) {
        return;
      } else if (score_type === 'total') {
        if (scores.status === ParticipantStatus.NO_SHOW.name) {
          res = 'NP'
        } else if (scores.semi) {
          res = 'semi'
        } else if (ParticipantStatus.is_eliminated(scores.status)) {
          res = scores.status
        }
      }
      if (typeof(res) === 'number' && res === 0) {
        return;
      }
      result_scores[score_type] = res
    })

  } else if (type === 'doma') {
    let score_order = score_types || ['total', 'P1', 'P2']
    score_order.forEach(score_type => {
      let res = scores[score_type.toLowerCase()]
      let extra = ''
      if (score_type === 'P1') {
        res = getScoreDoma(scores.state_p1, scores.p1)
      } else if (score_type === 'P2') {
        res = getScoreDoma(scores.state_p2, scores.p2)
      } else if (score_type === 'total') {
        if (res > 0) {
          res = res.toFixed(3)
        }
        extra = '%'
      }
      result_scores[score_type] = res + extra
    })
  } else {
    let total = {}
    scores.forEach(sc => {
      let key = Object.keys(sc)[0]
      let value = Object.values(sc)[0]
      if (key === 'TOTAL') {
        total = {key: value}
      } else {
        result_scores[key] = value
      }
    })
    result_scores = {...total, ...result_scores}
  }
  return result_scores
}
