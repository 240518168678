import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import footer from '../../assets/footer/footer@3x.png'


const styles = theme => ({
  box: {
    position: "relative",
  },
  footer: {
    height: 300,
    objectFit: "cover",
    width: "100%",
    marginBottom: -48,
  },
  textFooter: {
    position: "absolute",
    width: "95%",
    bottom: theme.spacing(2),
    left: "50%",
    transform: "translate(-50%)",
    color: "white",
  }
});


class Footer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.box}>
        <img className={classes.footer} src={footer} alt="footer" />
        <Typography className={classes.textFooter} variant="body2" display="inline">
          ANCCE Asociación Nacional de Criadores de Caballos de Pura Raza Española
          <br />
          © ANCCE {(new Date()).getFullYear()}
        </Typography>
      </Box>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
