export const loadVideo = (video) => {
  if (video.includes('youtu.be')) {
    return {
      type: 'video',
      sources: [{
        src: video,
        provider: 'youtube',
      }]
    }
  } else if (video.includes('vimeo')) {
    return {
      type: 'video',
      sources: [{
        src: video,
        provider: 'vimeo',
      }]
    }
  } else if (video.includes('clipmyhorse.tv')) {
    return {type: 'iframe', src: video}
  } else {
    return {
      type: 'video',
      sources: [{
        src: video,
        type: 'video/mp4',
      }]
    }
  }
}
