import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Button,
         Table, TableBody, TableCell, TableRow,
         Typography } from '@material-ui/core'
import { Link } from "react-router-dom"
import { withTheme, withStyles } from '@material-ui/core/styles'


import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { green } from "@material-ui/core/colors";

import { Check, ExpandMore } from "@material-ui/icons";

import API from 'services/Api.js'
import Footer from 'components/layout/Footer.js'
import Gallery from 'components/pages/participant/Gallery.js'
import GalleryVideo from 'components/pages/participant/GalleryVideo.js'
import Header from 'components/layout/Header.js'
import ParticipantCard from './ParticipantCard.js'
import ParticipantRow from './ParticipantRow.js'
import ParticipantSelect from './ParticipantSelect.js'
import ParticipantSelectSection from './ParticipantSelectSection.js'
import Searching from 'components/pages/shared/Searching.js'
import { loadImage } from 'utils/utils.js'

import esc_spain from 'assets/participant/esc_spain.png'
import esc_andalucia from 'assets/participant/esc_andalucia.png'
import default_participant from 'assets/participant/default.png'


const styles = theme => ({
  box: {
    borderBottom: "none",
  },
  boxModes: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  discipline: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  year: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: "1.5rem",
    lineHeight: 1,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
      fontWeight: 500,
    },
  },
  cellPos: {
    padding: 0,
  },
  table: {
    margin: 0,
  },
  leyend: {
    marginTop: theme.spacing(4),
  },
  imgEsc: {
    width: 12,
  },
  sonRow: {
    backgroundColor: "#f1f1f1",
    borderBottom: "1px solid white",
    height: 24,
  },
  accordion: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  expansionPanel: {
    color: 'white',
    backgroundColor: 'black',
    boxShadow: 'none',
    borderRadius: '0 !important'
  },
  expansionPanelDetails: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    textTransform: 'uppercase'
  },
  expansionPanelButton: {
    color: 'white'
  },
  expansionPanelCheck: {
    color: green[500],
    paddingLeft: theme.spacing(2)
  },
  accordionGrid: {
    alignItems: "center",
  },
  fullTreeButton: {
    textDecoration: "none",
    marginTop: theme.spacing(4),
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,

    whiteSpace: "break-spaces",
    display: 'block',
    minHeight: '30px',
    '&:hover': {
      backgroundColor: '#d1d1d1',
    }
  },
});

class Participant extends React.Component {
  constructor(props) {
    super(props);
    this._is_mounted = false;
    this.resize = this.resize.bind(this);
    this.options = ["concursos", "fotos", "videos", "genealogía"];
    this.state = {
      participant: {
        contests: {
          morf: [],
          doma: [],
          od: [],
        },
        exact: false,
        availables: [],
        searching: false,
      },
      sponsor: "ANCCE COVAP",
      currentBreakpoint: "",
      currentOption: "concursos",
    };
  }

  componentDidMount() {
    this._is_mounted = true;
    window.addEventListener("resize", this.resize);
    this.resize();
    this.search();
  }

  componentWillUnmount() {
    this._is_mounted = false;
    window.removeEventListener("resize", this.resize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.search();
    }
  }

  resize() {
    let res = "xs";
    if (window.innerWidth < this.props.theme.breakpoints.values["sm"]) {
      res = "xs";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["md"]) {
      res = "sm";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["lg"]) {
      res = "md";
    } else if (window.innerWidth < this.props.theme.breakpoints.values["xl"]) {
      res = "lg";
    } else {
      res = "xl";
    }
    if (this.state.currentBreakpoint !== res) {
      this.setState({ currentBreakpoint: res });
    }
  }

  groupBy = (arr, prop) => {
    let map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return map;
  };

  search = () => {
    this.setState({ searching: true });
    API.participant
      .searchParticipant(this.props.location.search)
      .then((resp) => {
        this.setState({ participant: resp, searching: false });
      })
      .catch((error) => {
        alert(
          "Error loading participant with params: " + this.props.location.search
        );
        this.setState({ searching: true });
      });
  };

  changeSection = (value) => {
    this.setState({ currentOption: value });
  };

  getLastParticipantImage = () => {
    let image;
    for (const value of this.state.participant.contests.morf.values()) {
      if (value.medias.img1) {
        image = value.medias.img1;
        break;
      }
    }

    if (!image) {
      for (const value of this.state.participant.contests.doma.values()) {
        if (value.medias.img1) {
          image = value.medias.img1;
          break;
        }
      }
    }

    if (!image) {
      return default_participant;
    }

    return loadImage(image);
  };

  render() {
    const { classes } = this.props;
    const heads = {
      morf: [
        "POS",
        "Concurso",
        "Sec.",
        "Ejemplar/G. Titular",
        "Punt.",
        "",
        "PE",
      ],
      doma: ["POS", "Concurso", "Nivel", "Ejemplar/G. Titular", "Punt.", ""],
      od: ["POS", "Concurso", "Nivel", "Ejemplar/Criador", "Punt.", ""],
    };
    const isMobile = ["xs"].includes(this.state.currentBreakpoint)
      ? true
      : false;
    const disciplines = [
      {
        id: "morf",
        name: "MORFOLÓGICOS",
        participants: this.groupBy(this.state.participant.contests.morf, "year"),
      },
      {
        id: "doma",
        name: "DOMA",
        participants: this.groupBy(this.state.participant.contests.doma, "year"),
      },
      {
        id: "od",
        name: "OTRAS DISCIPLINAS",
        participants: this.groupBy(this.state.participant.contests.od, "year"),
      },
    ];

    const last_participant_img = this.getLastParticipantImage();

    if (!this.state.participant.specimen && this.state.participant.availables.length === 0) {
      return [
        <Header key={0} />,
        <ParticipantSelect key={1} availables={[]} />
      ];
    }

    // SEARCHING
    if (this.state.searching) {
      return <Searching />;
    }

    // EXACT = False: Show availables participants
    if (!this.state.participant.exact) {
      return [
        <Header key={0} />,
        <ParticipantSelect key={1} availables={this.state.participant.availables} />
      ];
    }

    // EXACT = True: Show exact participant
    return (
      <Box className={classes.box}>
        <Header />

        <ParticipantCard
          participant={this.state.participant.specimen}
          img={last_participant_img}
        />

        <ParticipantSelectSection
          isMobile={isMobile}
          options={this.options}
          changeSection={this.changeSection}
          currentOption={this.state.currentOption}
        />

        {/* PHOTOS */}
        {this.state.currentOption === "fotos" && (
          <Gallery participants={this.state.participant.contests.morf} />
        )}

        {/* VIDEOS */}
        {this.state.currentOption === "videos" && (
          <GalleryVideo participant={this.state.participant} />
        )}

        {/* CONTEST */}
        {this.state.currentOption === "concursos" && (
          <Box className={classes.boxModes}>
            {disciplines.map((discipline, index) => {
              return (
                <Box key={index}>
                  {Array.from(discipline.participants.keys()).length > 0 && (
                    <Typography
                      className={classes.discipline}
                      align='left'
                      variant='h4'
                    >
                      {discipline.name}
                    </Typography>
                  )}

                  <Table className={classes.table}>
                    {Array.from(discipline.participants.keys()).map(
                      (year, indexY) => {
                        return (
                          <TableBody key={`${index}y${indexY}`}>
                            {/* YEARS */}
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Typography
                                  className={classes.year}
                                  align='left'
                                >
                                  {year}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            {/* HEADS */}
                            {!isMobile &&
                              Array.from(discipline.participants.keys())
                                .length > 0 && (
                                <TableRow>
                                  {heads[discipline.id].map((value, indexH) => {
                                    return (
                                      <TableCell
                                        className={
                                          value === "POS" ? classes.cellPos : ""
                                        }
                                        key={`${index}h${indexH}`}
                                        align='left'
                                      >
                                        <Typography variant='h6'>
                                          {value}
                                        </Typography>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              )}

                            {/* PARTICIPANTS */}
                            {discipline.participants
                              .get(year)
                              .map((parti, indexP) => {
                                return (
                                  <ParticipantRow
                                    key={`${index}y${indexY}p${indexP}`}
                                    isMobile={isMobile}
                                    participant={parti}
                                  />
                                );
                              })}
                          </TableBody>
                        );
                      }
                    )}
                  </Table>
                </Box>
              );
            })}
            <Typography variant='body2' className={classes.leyend}>
              M.M. (mejores movimientos) | M.F. (mejor funcionalidad)
              <br />
              <img
                className={classes.imgEsc}
                src={esc_spain}
                alt='pe spain'
              />{" "}
              (Campeón) |
              <img
                className={classes.imgEsc}
                src={esc_andalucia}
                alt='pe spain'
              />{" "}
              (Subcampeón) <br />C (campeón del concurso) | M (mejores
              movimientos) | F (mejor funcionalidad)
            </Typography>
          </Box>
        )}

        {/* LG */}
        {this.state.currentOption === "genealogía" && (
          <Box className={classes.boxModes}>
            <Grid container className={classes.accordion}>
              <Grid item xs={12}>
                <ExpansionPanel className={classes.expansionPanel}>
                  <ExpansionPanelSummary expandIcon={<ExpandMore className={classes.expansionPanelButton}/>}>
                    <Grid
                      container
                      spacing={4}
                      className={classes.accordionGrid}
                    >
                      <Grid item xs={6}>
                        <Typography variant='h6'>Hijos ({ this.state.participant.specimen.children.length })</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='h6'>En concurso ({ this.state.participant.specimen.children.filter(sp => sp.in_contest).length })</Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <Grid container className={classes.accordionGrid}>
                    { this.state.participant.specimen.children.sort((a, b) => (a.name > b.name) ? 1 : -1).map((child, key) => {
                        return (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Link className={classes.link} to={ "/participant?code=" + child.code }>
                                <Grid container>
                                  <Grid item xs={6}>
                                      { child.name }
                                  </Grid>
                                  <Grid item xs={6}>
                                    { child.in_contest &&
                                      <Typography>
                                        <Check className={classes.expansionPanelCheck} />
                                      </Typography>
                                    }
                                  </Grid>
                                </Grid>
                              </Link>
                            </Grid>
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>

            <Grid container className={classes.accordion}>
              <Grid item xs={12}>
                <ExpansionPanel className={classes.expansionPanel}>
                  <ExpansionPanelSummary expandIcon={<ExpandMore className={classes.expansionPanelButton}/>}>
                    <Grid
                      container
                      spacing={4}
                      className={classes.accordionGrid}
                    >
                      <Grid item xs={6}>
                        <Typography variant='h6'>Hermanos ({ this.state.participant.specimen.siblings.length })</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='h6'>En concurso ({ this.state.participant.specimen.siblings.filter(sp => sp.in_contest).length })</Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <Grid container className={classes.accordionGrid}>
                      { this.state.participant.specimen.siblings.sort((a, b) => (a.name > b.name) ? 1 : -1).map((sibling, key) => {
                        return (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Link className={classes.link} to={ "/participant?code=" + sibling.code }>
                                <Grid container>
                                  <Grid item xs={6}>
                                      { sibling.name }
                                  </Grid>
                                  <Grid item xs={6}>
                                    { sibling.in_contest &&
                                      <Typography>
                                        <Check className={classes.expansionPanelCheck} />
                                      </Typography>
                                    }
                                  </Grid>
                                </Grid>
                              </Link>
                            </Grid>
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>

            <a
              className={classes.fullTreeButton}
              href={this.state.participant.lg_link}
              target='_blank'
              rel='noopener noreferrer'
              align='left'
            >
              <Button
                variant='contained'
                className={classes.fullTreeButton}
                color='primary'
              >
                Ver árbol completo
              </Button>
            </a>
          </Box>
        )}

        <Footer />
      </Box>
    );
  }
}

Participant.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(Participant));
