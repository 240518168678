import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Button, TableCell, TableRow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import DialogMultimedia from "components/layout/DialogMultimedia.js";
import Scores from "./Scores.js";
import multimedia from "assets/contest/multimedia.png";

const styles = (theme) => ({
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    height: 48,
  },
  cellPos: {
    fontWeight: "bold",
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
  },
  link: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    padding: 0,
    whiteSpace: "break-spaces",
  },
  cellJumpLine: {
    whiteSpace: "break-spaces",
  },
  cellMobile: {
    margin: 0,
    padding: 0,
  },
  cellMobilePos: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 32,
    lineHeight: 2,
  },
  extraWhiteCell: {
    margin: 0,
    padding: 0,
    height: 1,
  },
  multimedia: {
    minWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: "4px 8px",
  },
  multimediaImg: {
    width: "32px!important",
  },
});

class ParticipantScoreOtherDiscipline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      open: false,
    };
  }

  handleDialog = (e) => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  handleClose = (e) => {
    this.setState({ open: false });
  };

  checkMedias = (medias) => {
    return Object.entries(medias).filter((m) => m[1]).length > 0;
  };

  getParticipantScoreByName = (name) => {
    let res = this.props.participant.extras.filter(
      (sc) => sc.title.toLowerCase() === name
    );
    return res.length === 1 ? res[0].value.replaceAll("<br/>", "\n") : "";
  };

  isScoreEmpty = (scores) => {
    let res = true;
    scores.forEach((score) => {
      if (score.punt) {
        res = false;
      }
    });
    return res;
  };

  render() {
    const { classes, participant } = this.props;
    const existMedias = this.checkMedias(participant.medias);

    let video1 = participant.medias.P1;
    let video2 = participant.medias.P2;
    let video3 = null;
    let video4 = null;
    let video1_desc = "Video P1";
    let video2_desc = "Video P2";
    let video3_desc = "";
    let video4_desc = "";

    if (this.props.discipline === "en") {
      video1 = participant.medias.MANAGEABILITY;
      video2 = participant.medias.MARATHON;
      video3 = participant.medias.DRESSAGE;
      video1_desc = "Video manejabilidad";
      video2_desc = "Video maratón";
      video3_desc = "Video doma";
    } else if (this.props.discipline === "et") {
      video3 = participant.medias.DRESSAGE;
      video1 = participant.medias.MANAGEABILITY;
      video2 = participant.medias.VELOCITY;
      video4 = participant.medias.VACAS;
      video3_desc = "Video doma";
      video1_desc = "Video manejabilidad";
      video2_desc = "Video velocidad";
      video4_desc = "Video vacas";
    }

    let fields = null;
    let mobile_fields = null;

    if (this.props.discipline === "ama") {
      fields = (
        <TableRow className={classes.tableRowParticipant}>
          <TableCell
            classes={{ root: classes.cellRoot }}
            className={classes.cellPos}
            align="center"
          >
            {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {existMedias && (
                <Button
                  className={classes.multimedia}
                  color="primary"
                  onClick={this.handleDialog}
                >
                  <img
                    className={classes.multimediaImg}
                    src={multimedia}
                    alt="multimedia"
                  />
                </Button>
              )}
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.cellJumpLine} variant="body2">
              {this.getParticipantScoreByName("amazona")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {this.getParticipantScoreByName("criador")}
            </Typography>
          </TableCell>
          <TableCell>
            <Scores scores={participant.scores} />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
      mobile_fields = [
        <TableRow key={1}>
          <TableCell className={classes.cellMobile} align="center" colSpan={2}>
            <Typography
              className={classes.cellMobilePos}
              variant="body2"
              noWrap={true}
            >
              {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
            </Typography>
          </TableCell>
        </TableRow>,
        <TableRow key={2} className={classes.tableRowParticipant}>
          <TableCell>
            <Typography variant="body2">
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
            <Typography className={classes.cellJumpLine} variant="body2">
              C: {this.getParticipantScoreByName("criador")}
            </Typography>
            <Typography variant="body2">
              Ama.: {this.getParticipantScoreByName("amazona")}
            </Typography>
          </TableCell>
          <TableCell className={classes.cellMultimedia} align="center">
            {existMedias && (
              <Button
                className={classes.multimedia}
                color="primary"
                onClick={this.handleDialog}
              >
                <img
                  className={classes.multimediaImg}
                  src={multimedia}
                  alt="multimedia"
                />
              </Button>
            )}
            <Scores scores={participant.scores} />
          </TableCell>
        </TableRow>,
      ];
    } else if (this.props.discipline === "en") {
      fields = (
        <TableRow className={classes.tableRowParticipant}>
          <TableCell
            classes={{ root: classes.cellRoot }}
            className={classes.cellPos}
            align="center"
          >
            {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {existMedias && (
                <Button
                  className={classes.multimedia}
                  color="primary"
                  onClick={this.handleDialog}
                >
                  <img
                    className={classes.multimediaImg}
                    src={multimedia}
                    alt="multimedia"
                  />
                </Button>
              )}
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {this.getParticipantScoreByName("cochero")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.cellJumpLine} variant="body2">
              {participant.livestock}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.cellJumpLine} variant="body2">
              {this.getParticipantScoreByName("criador")}
            </Typography>
          </TableCell>
          <TableCell>
            <Scores scores={participant.scores} />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
      mobile_fields = [
        <TableRow key={1}>
          <TableCell className={classes.cellMobile} align="center" colSpan={2}>
            <Typography
              className={classes.cellMobilePos}
              variant="body2"
              noWrap={true}
            >
              {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
            </Typography>
          </TableCell>
        </TableRow>,
        <TableRow key={2} className={classes.tableRowParticipant}>
          <TableCell>
            <Typography variant="body2">
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
            <Typography variant="body2">
              Cochero: {this.getParticipantScoreByName("cochero")}
            </Typography>
            <Typography className={classes.cellJumpLine} variant="body2">
              P: {participant.livestock}
            </Typography>
            <Typography className={classes.cellJumpLine} variant="body2">
              C: {this.getParticipantScoreByName("criador")}
            </Typography>
          </TableCell>
          <TableCell className={classes.cellMultimedia} align="center">
            {existMedias && (
              <Button
                className={classes.multimedia}
                color="primary"
                onClick={this.handleDialog}
              >
                <img
                  className={classes.multimediaImg}
                  src={multimedia}
                  alt="multimedia"
                />
              </Button>
            )}
            <Scores scores={participant.scores} />
          </TableCell>
        </TableRow>,
      ];
    } else if (["et", "ae", "dv", "sa"].includes(this.props.discipline)) {
      fields = (
        <TableRow className={classes.tableRowParticipant}>
          <TableCell
            classes={{ root: classes.cellRoot }}
            className={classes.cellPos}
            align="center"
          >
            {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {existMedias && (
                <Button
                  className={classes.multimedia}
                  color="primary"
                  onClick={this.handleDialog}
                >
                  <img
                    className={classes.multimediaImg}
                    src={multimedia}
                    alt="multimedia"
                  />
                </Button>
              )}
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {this.getParticipantScoreByName("jinete")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.cellJumpLine} variant="body2">
              {participant.livestock}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.cellJumpLine} variant="body2">
              {this.getParticipantScoreByName("criador")}
            </Typography>
          </TableCell>
          <TableCell>
            <Scores scores={participant.scores} />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
      mobile_fields = [
        <TableRow key={1}>
          <TableCell className={classes.cellMobile} align="center" colSpan={2}>
            <Typography
              className={classes.cellMobilePos}
              variant="body2"
              noWrap={true}
            >
              {this.isScoreEmpty(participant.scores) ? "-" : participant.pos}
            </Typography>
          </TableCell>
        </TableRow>,
        <TableRow key={2} className={classes.tableRowParticipant}>
          <TableCell>
            <Typography variant="body2">
              {participant.name.map((parti, i) => {
                return (
                  <Link
                    key={i}
                    className={classes.link}
                    to={"/participant?code=" + participant.code[i]}
                    onClick={this.handleClick}
                  >
                    {parti + "\n"}
                  </Link>
                );
              })}
            </Typography>
            <Typography className={classes.cellJumpLine} variant="body2">
              P: {participant.livestock}
            </Typography>
            <Typography className={classes.cellJumpLine} variant="body2">
              C: {this.getParticipantScoreByName("criador")}
            </Typography>
            <Typography variant="body2">
              J: {this.getParticipantScoreByName("jinete")}
            </Typography>
          </TableCell>
          <TableCell className={classes.cellMultimedia} align="center">
            {existMedias && (
              <Button
                className={classes.multimedia}
                color="primary"
                onClick={this.handleDialog}
              >
                <img
                  className={classes.multimediaImg}
                  src={multimedia}
                  alt="multimedia"
                />
              </Button>
            )}
            <Scores scores={participant.scores} />
          </TableCell>
        </TableRow>,
      ];
    }

    return (
      <React.Fragment>
        {this.props.isMobile ? (
          <React.Fragment>{mobile_fields}</React.Fragment>
        ) : (
          <React.Fragment>{fields}</React.Fragment>
        )}

        <TableRow>
          <TableCell className={classes.extraWhiteCell} colSpan={8}></TableCell>
          <DialogMultimedia
            key={participant.pos}
            previous={this.props.previous}
            list={this.props.list}
            next={this.props.next}
            title={participant.pos + " - " + participant.name}
            img1={participant.medias.img1}
            video1={video1}
            video2={video2}
            video3={video3}
            video4={video4}
            video1_desc={video1_desc}
            video2_desc={video2_desc}
            video3_desc={video3_desc}
            video4_desc={video4_desc}
            open={this.state.open}
            onClick={this.handleClick}
            onClose={this.handleClose}
          />
        </TableRow>
      </React.Fragment>
    );
  }
}

ParticipantScoreOtherDiscipline.propTypes = {
  classes: PropTypes.object.isRequired,
};

ParticipantScoreOtherDiscipline.defaultProps = {
  isMobile: false,
};

export default withStyles(styles)(ParticipantScoreOtherDiscipline);
