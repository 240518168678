import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


class Index extends PureComponent {
  state = {
    contentCached: false,
    updateAvailable: false,
    swRegistration: null,
  }

  componentDidMount() {
    const config = {
      onSuccess: this.handleSuccess,
      onUpdate: this.handleUpdate,
    }
    serviceWorker.register(config)
  }

  render() {
    const { contentCached, updateAvailable, swRegistration } = this.state
    return <App contentCached={contentCached} updateAvailable={updateAvailable} swRegistration={swRegistration} />
  }

  handleUpdate = (reg) => {
    this.setState({
      updateAvailable: true,
      swRegistration: reg
    })
  }

  handleSuccess = () => {
    this.setState({ contentCached: true })
  }
}


ReactDOM.render(<Index />, document.getElementById('root'))
