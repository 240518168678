import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";

import ContestSectionPE from "./ContestSectionPE.js";
import ContestLevelBestBreeder from "./ContestLevelBestBreeder.js";
import ContestLevelBestBreederMobile from "./ContestLevelBestBreederMobile.js";
import ParticipantScore from "components/pages/participant/ParticipantScore.js";
import ParticipantScoreDoma from "components/pages/participant/ParticipantScoreDoma.js";
import ParticipantScoreOtherDiscipline from "components/pages/participant/ParticipantScoreOtherDiscipline.js";
import Video from "components/pages/contest/Video.js";
import Sections from "components/pages/contest/Sections.js";
import { loadVideo } from "utils/video.js";
import Loader from "components/ui/Loader.js";

const styles = (theme) => ({
  waiting: {
    marginTop: "5%",
    marginLeft: "5%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  gridParent: {
    width: "100%",
    margin: 0,
    padding: "0 16px 0 16px",
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      padding: "0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      flexWrap: "wrap",
    },
  },
  gridSection: {
    minWidth: 0,
    padding: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "4px!important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px!important",
      marginTop: theme.spacing(2),
    },
  },
  table: {
    width: "auto",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    textAlign: "left",
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    textAlign: "left",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 16,
    },
  },
  cellPos: {
    padding: 0,
    maxWidth: 32,
  },
  leyend: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "grey",
  },
  amountParticipant: {
    marginTop: 16,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 16,
    },
  },
  extraInfo: {
    display: "flex",
    flexWrap: "wrap",
  },
  video: {
    margin: 16,
    width: 320,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
      marginTop: 16,
    },
  },
  btnGroupRoundSections: {
    display: "block",
    textAlign: "left",
    borderRadius: 0,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    marginTop: "1rem",
  },
  btnRoundSection: {
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "5px 6px",
    },
  },
  btnRoundSectionSelected: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 6px",
    },
  },
});

class ContestSections extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getSectionName = () => {
    let res =
      this.props.contest.sections &&
      this.props.contest.sections[this.props.section];
    return res ? res : this.props.title;
  };

  getSection = (id, name) => {
    let res = "";
    if (this.props.discipline === "morf") {
      if (id !== "pe") {
        res = "S" + id;
      } else {
        res = id;
      }
    } else if (this.props.discipline === "doma") {
      res = name;
    } else {
      res = name;
    }
    return res;
  };

  participants;
  updateParticipants = () => {
    if (this.props.round === "semifinales") {
      this.participants = this.props.participants_semifinal.sort(
        (a, b) => a.number - b.number
      );
      return;
    }

    this.participants = this.props.participants;
  };

  render() {
    const { classes } = this.props;

    // Variables by contest type
    let leyend = null;
    let headers = null;
    if (this.props.discipline === "morf") {
      leyend = (
        <Box className={classes.leyend}>
          M.M. (mejores movimientos) | M.F. (mejor funcionalidad) | NP (no
          presentado) | ret. (retirado) | elim. (eliminado) | VC (Voto de calidad)
        </Box>
      );
      headers = (
        <TableRow>
          <TableCell className={classes.cellPos} align="center">
            POS.
          </TableCell>
          <TableCell>DOR.</TableCell>
          <TableCell>EJEMPLAR</TableCell>
          <TableCell>TITULAR</TableCell>
          <TableCell>CRIADOR</TableCell>
          <TableCell>PUNTUACIÓN</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      );
    } else if (this.props.discipline === "doma") {
      leyend = (
        <Box className={classes.leyend}>
          <Typography align="left" variant="body2">
            <b>RET.</b> (El ejemplar sale a pista y se retira)
          </Typography>
          <Typography align="left" variant="body2">
            <b>ELIM.</b> (El ejemplar sale a pista y es eliminado por los
            jueces)
          </Typography>
          <Typography align="left" variant="body2">
            <b>NP-JUS.</b> (No sale a pista. Justificado mediante certificado
            veterinario)
          </Typography>
          <Typography align="left" variant="body2">
            <b>NO INS.</b> (No se ha inscrito en esta prueba)
          </Typography>
          <Typography align="left" variant="body2">
            <b>NP.</b> (No realiza la prueba)
          </Typography>
          <Typography align="left" variant="body2">
            <b>KÜR</b> (Al realiza kür, se toma únicamente resultado día 1)
          </Typography>
        </Box>
      );
      headers = (
        <TableRow>
          <TableCell className={classes.cellPos} align="center">
            POS.
          </TableCell>
          <TableCell>EJEMPLAR</TableCell>
          <TableCell>
            <Typography variant="body2">
              <b>PADRE</b>
            </Typography>
            <Typography variant="body2">
              <b>MADRE</b>
            </Typography>
          </TableCell>
          <TableCell>JINETE</TableCell>
          <TableCell>
            <Typography variant="body2">
              <b>TITULAR</b>
            </Typography>
            <Typography variant="body2">
              <b>CRIADOR</b>
            </Typography>
          </TableCell>
          <TableCell>PUNTUACIÓN</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      );
    } else if (this.props.discipline === "ama") {
      headers = (
        <TableRow>
          <TableCell className={classes.cellPos} align="center">
            POS.
          </TableCell>
          <TableCell>EJEMPLAR</TableCell>
          <TableCell>AMAZONAS</TableCell>
          <TableCell>CRIADOR</TableCell>
          <TableCell>PUNTUACIÓN</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      );
    } else if (this.props.discipline === "en") {
      headers = (
        <TableRow>
          <TableCell className={classes.cellPos} align="center">
            POS.
          </TableCell>
          <TableCell>EJEMPLAR</TableCell>
          <TableCell>COCHERO</TableCell>
          <TableCell>PARTICIPANTE</TableCell>
          <TableCell>CRIADOR</TableCell>
          <TableCell>PUNTUACIÓN</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      );
    } else if (["et", "ae", "dv", "sa"].includes(this.props.discipline)) {
      headers = (
        <TableRow>
          <TableCell className={classes.cellPos} align="center">
            POS.
          </TableCell>
          <TableCell>EJEMPLAR</TableCell>
          <TableCell>JINETE</TableCell>
          <TableCell>PARTICIPANTE</TableCell>
          <TableCell>CRIADOR</TableCell>
          <TableCell>PUNTUACIÓN</TableCell>
          <TableCell></TableCell>
          <TableCell />
        </TableRow>
      );
    } else {
      leyend = null;
    }

    let sections = this.props.contest.sections;
    if (this.props.discipline === "doma" && this.props.contest.sicab) {
      sections["mg"] = "Mejor Ganadería";
    }

    const round_sections = ["semifinales", "finales"];
    this.updateParticipants();

    if (this.props.isMobile) {
      return (
        <React.Fragment>
          {/* sections */}
          <Sections
            isMobile={this.props.isMobile}
            sections={sections}
            section={this.props.section}
            discipline={this.props.discipline}
            changeSelectSection={this.props.changeSelectSection}
            changeButtonGroupSection={this.props.changeButtonGroupSection}
          />

          {this.props.contest.extra &&
            this.props.contest.extra.closed_inscription &&
            Object.keys(this.props.contest.sections).length === 0 && (
              <Typography
                className={classes.subtitle}
                variant="subtitle2"
                align="left"
              >
                Ejemplares pendientes de publicar por parte del Comité
                Organizador
              </Typography>
            )}

          {this.props.section && !["pe", "mg"].includes(this.props.section) && (
            <Box className={classes.amountParticipant}>
              <Typography variant="body1" align="left">
                Número de ejemplares participantes:{" "}
                {this.props.amount_participants}
              </Typography>
              <Typography variant="body1" align="left">
                Número de ejemplares participantes en esta sección:{" "}
                {this.participants.length}
              </Typography>
            </Box>
          )}

          {this.props.extra_info && (
            <Box className={classes.extraInfo}>
              {this.props.extra_info.map((extra, key) => {
                return (
                  <Box key={extra.video_link} className={classes.video}>
                    <div dangerouslySetInnerHTML={{ __html: extra.html }}></div>
                    {extra.video_link && (
                      <Video sources={loadVideo(extra.video_link)} />
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {this.canDisplayRoundSelector() && (
            <ButtonGroup
              className={classes.btnGroupRoundSections}
              color="secondary"
            >
              {round_sections.map((round_section, key) => {
                const cn =
                  round_section.toLowerCase() === this.props.round.toLowerCase()
                    ? classes.btnRoundSectionSelected
                    : classes.btnRoundSection;
                return (
                  <Button
                    disabled={
                      round_section === "finales" &&
                      !this.props.contest.semifinal
                    }
                    key={key}
                    className={cn}
                    onClick={() => {
                      this.props.changeRoundSection(round_section);
                    }}
                  >
                    {round_section}
                  </Button>
                );
              })}
            </ButtonGroup>
          )}

          {/* TABLE COLLAPSIBLE */}
          {this.props.searching && <Loader class={classes.waiting} />}

          {!this.props.searching &&
            this.props.section !== "" &&
            this.props.section !== "pe" &&
            this.props.section !== "mg" && (
              <React.Fragment>
                <Table className={classes.table}>
                  <TableBody>
                    {this.props.discipline === "morf" &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScore
                            key={"m" + key}
                            contest={this.props.contest}
                            judges={{
                              M: this.props.jmorf,
                              F: this.props.jfunc,
                            }}
                            jsemi={this.props.jsemi}
                            mf={this.props.mf}
                            section={this.props.section}
                            round={this.props.round}
                            participant={p}
                            isMobile={this.props.isMobile}
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                          />
                        );
                      })}
                    {this.props.discipline === "doma" &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScoreDoma
                            key={"d" + key + "s" + this.props.section}
                            participant={p}
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                            isMobile={this.props.isMobile}
                            scoresDefinition={this.props.scoresDefinition}
                            daysDifferent={this.props.daysDifferent}
                          />
                        );
                      })}
                    {!["morf", "doma"].includes(this.props.discipline) &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScoreOtherDiscipline
                            key={"od" + key}
                            participant={p}
                            isMobile={this.props.isMobile}
                            discipline={this.props.discipline}
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                {leyend}
              </React.Fragment>
            )}

          {/* MORF: Special Award */}
          {!this.props.searching && this.props.section === "pe" && (
            <React.Fragment>
              <ContestSectionPE
                regulation={this.props.contest.regulation}
                pe={this.props.pe}
                pe_scores={this.props.pe_scores}
                isMobile={this.props.isMobile}
              />
              {leyend}
            </React.Fragment>
          )}

          {/* DOMA: Best Breeder */}
          {this.props.contest.sicab && this.props.section === "mg" && (
            <React.Fragment>
              <ContestLevelBestBreederMobile
                contest_code={this.props.contest.code}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* sections */}
          <Sections
            isMobile={this.props.isMobile}
            sections={sections}
            section={this.props.section}
            discipline={this.props.discipline}
            changeSelectSection={this.props.changeSelectSection}
            changeButtonGroupSection={this.props.changeButtonGroupSection}
          />

          {/* sections */}
          <Typography className={classes.subtitle} variant="subtitle1">
            {this.getSectionName()}
          </Typography>

          {this.props.contest.extra &&
            this.props.contest.extra.closed_inscription &&
            Object.keys(this.props.contest.sections).length === 0 && (
              <Typography
                className={classes.subtitle}
                variant="subtitle2"
                align="left"
              >
                Ejemplares pendientes de publicar por parte del Comité
                Organizador
              </Typography>
            )}

          {this.props.section && !["pe", "mg"].includes(this.props.section) && (
            <Box className={classes.amountParticipant}>
              <Typography variant="body1" align="left">
                Número de ejemplares participantes:{" "}
                {this.props.amount_participants}
              </Typography>
              <Typography variant="body1" align="left">
                Número de ejemplares participantes en esta sección:{" "}
                {this.participants.length}
              </Typography>
            </Box>
          )}

          {this.props.extra_info && (
            <Box className={classes.extraInfo}>
              {this.props.extra_info.map((extra, key) => {
                return (
                  <Box key={extra.video_link} className={classes.video}>
                    <div dangerouslySetInnerHTML={{ __html: extra.html }}></div>
                    {extra.video_link && (
                      <Video sources={loadVideo(extra.video_link)} />
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {this.props.section &&
            this.props.contest.semifinal &&
            this.props.participants_semifinal &&
            this.props.participants_semifinal.length > 0 &&
            this.props.round && (
              <ButtonGroup
                className={classes.btnGroupRoundSections}
                color="secondary"
              >
                {round_sections.map((round_section, key) => {
                  const cn =
                    round_section.toLowerCase() ===
                    this.props.round.toLowerCase()
                      ? classes.btnRoundSectionSelected
                      : classes.btnRoundSection;
                  return (
                    <Button
                      disabled={
                        round_section === "finales" &&
                        !this.props.contest.semifinal
                      }
                      key={key}
                      className={cn}
                      onClick={() => {
                        this.props.changeRoundSection(round_section);
                      }}
                    >
                      {round_section}
                    </Button>
                  );
                })}
              </ButtonGroup>
            )}

          {/* TABLE COLLAPSIBLE */}
          {this.props.searching && <Loader class={classes.waiting} />}

          {!this.props.searching &&
            this.props.section !== "" &&
            this.props.section !== "pe" &&
            this.props.section !== "mg" && (
              <React.Fragment>
                <Table className={classes.table}>
                  <TableHead>{headers}</TableHead>
                  <TableBody>
                    {this.props.discipline === "morf" &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScore
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                            key={"m" + key}
                            contest={this.props.contest}
                            judges={{
                              M: this.props.jmorf,
                              F: this.props.jfunc,
                            }}
                            jsemi={this.props.jsemi}
                            mf={this.props.mf}
                            section={this.props.section}
                            round={this.props.round}
                            participant={p}
                            isMobile={this.props.isMobile}
                          />
                        );
                      })}
                    {this.props.discipline === "doma" &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScoreDoma
                            key={"d" + key + "s" + this.props.section}
                            participant={p}
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                            isMobile={this.props.isMobile}
                            scoresDefinition={this.props.scoresDefinition}
                            daysDifferent={this.props.daysDifferent}
                          />
                        );
                      })}

                    {!["morf", "doma"].includes(this.props.discipline) &&
                      this.participants.map((p, key) => {
                        return (
                          <ParticipantScoreOtherDiscipline
                            previous={key - 1}
                            list={this.participants}
                            next={key + 1}
                            key={"d" + key}
                            participant={p}
                            isMobile={this.props.isMobile}
                            discipline={this.props.discipline}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                {leyend}
              </React.Fragment>
            )}

          {/* MORF: Special Award */}
          {!this.props.searching && this.props.section === "pe" && (
            <React.Fragment>
              <ContestSectionPE
                regulation={this.props.contest.regulation}
                pe={this.props.pe}
                pe_scores={this.props.pe_scores}
                isMobile={this.props.isMobile}
              />
              {leyend}
            </React.Fragment>
          )}

          {/* DOMA: Best Breeder */}
          {this.props.contest.sicab && this.props.section === "mg" && (
            <ContestLevelBestBreeder contest_code={this.props.contest.code} />
          )}
        </React.Fragment>
      );
    }
  }

  canDisplayRoundSelector() {
    return (
      this.props.section &&
      this.props.contest.semifinal &&
      this.props.participants_semifinal &&
      this.props.participants_semifinal.length > 0 &&
      this.props.round
    );
  }
}

ContestSections.propTypes = {
  classes: PropTypes.object.isRequired,
};
ContestSections.defaultProps = {
  title: "Seleccione una sección",
};

export default withTheme(withStyles(styles)(ContestSections));
