import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, ButtonGroup, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MenuBook, List, Photo, Videocam } from '@material-ui/icons'


const styles = theme => ({
  box: {
  },
  btnGroup: {
    borderRadius: 0,
    fontWeight: "bold",
  },
  btn: {
    borderRadius: 0,
    height: 48,
    fontWeight: "bold",
    padding: "0px 64px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 14px",
    },
  },
  btnSelected: {
    borderRadius: 0,
    height: 48,
    fontWeight: "bold",
    padding: "0px 64px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 12px",
    },
    backgroundColor: theme.palette.secondary.main,
    border: 0,
    color: "white",
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    }
  },
  btnMobile: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "end",
    minWidth: 75,
  },
  icon: {
    width: 24,
    paddingBottom: 4,
    paddingLeft: 4,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      marginBottom: -4,
    },
  },
  textBtn: {
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 1,
  },
});


class ParticipantSelectSection extends React.Component {

  render() {
    const { classes } = this.props

    return (
      <Box className={classes.box}>
        <ButtonGroup className={classes.btnGroup} color="secondary">
          {this.props.options.map((opt, i) => {
            return <Button key={i}
                           className={[this.props.currentOption === opt ? classes.btnSelected : classes.btn, this.props.isMobile && classes.btnMobile]}
                           onClick={() => this.props.changeSection(opt)}>
                            {this.props.isMobile ? (
                                <Box>
                                  <Typography className={classes.textBtn}>
                                    {opt}
                                  </Typography>
                                  {opt === 'concursos' && <List className={classes.icon} /> }
                                  {opt === 'fotos' && <Photo className={classes.icon} /> }
                                  {opt === 'videos' && <Videocam className={classes.icon} /> }
                                  {opt === 'genealogía' && <MenuBook className={classes.icon} /> }
                                </Box>
                              ) : (
                                <React.Fragment>
                                  {opt}
                                  {opt === 'concursos' && <List className={classes.icon} /> }
                                  {opt === 'fotos' && <Photo className={classes.icon} /> }
                                  {opt === 'videos' && <Videocam className={classes.icon} /> }
                                  {opt === 'genealogía' && <MenuBook className={classes.icon} /> }
                                </React.Fragment>
                              )
                            }
                   </Button>
          })}
        </ButtonGroup>
      </Box>
    );
  }
}

ParticipantSelectSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantSelectSection)
