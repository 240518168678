import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom"
import { Button } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: "right",
    fontFamily: "Yantramanav",
    fontSize: 16,
    fontWeight: "bold",
    padding: "4px 16px",
  },
}));

const RankingButton = (props) => {
  const classes = useStyles();

  return (
    <Link style={{ textDecoration: "none" }} to={props.url}>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
      >
        {props.title}
      </Button>
    </Link>
  );
}

export default RankingButton;
