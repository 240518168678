import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { Box, Collapse,
         Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ParticipantStatus } from 'utils/utils.js'


const styles = theme => ({
  tableRowExpandMobile: {
    margin: 0,
    padding: 0,
    backgroundColor: "#f1f1f1",
    color: theme.palette.primary.main,
    height: 16,
    borderBottom: "4px solid white",
  },
  collapse: {
    overflowX: "auto",
  },
  collapseBox: {
    width: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: "0px 8px",
    }
  },
  subtable: {
    overflowX: "auto",
  },
  cellExpand: {
    padding: 0,
    width: 400,
  },
  cellSubtableRight: {
    padding: "0px 8px",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  cellSubtable: {
    padding: "0px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  smallFont: {
    fontSize: 12,
  },
  total: {
    fontSize: 18,
    fontWeight: "bold",
    padding: "0px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  link: {
    color: "black",
  },
});


class ParticipantScoreCellExpand extends React.Component {
  getScore = (score) => {
    if (score && typeof(score) === 'number') {
      return score.toFixed(3)
    }
  }

  getJudgeIdByIndex = (index, type) => {
    let id = 0
    this.props.judges[type].forEach(j => {
      if (j.alias.includes(String(index))) {
        id = j.id
      }
      return ''
    })
    return id
  }

  shouldExpand = () => {
    let participant = this.props.participant
    let jmorf = participant.jmorf ? participant.jmorf.filter(j=>j>0).length : 0
    let jmov = participant.jmov ? participant.jmov.filter(j=>j>0).length : 0
    let jfunc = participant.jfunc ? participant.jfunc.filter(j=>j>0).length : 0
    let jtotal = participant.jtotal ? participant.jtotal.filter(j=>j>0).length : 0
    return (Math.max(jmorf, jmov, jfunc, jtotal) > 0)
  }

  render() {
    const { classes, participant } = this.props
    const shouldExpand = this.shouldExpand()
    let morf = <span>morf.</span>
    let mov = <span>mov.</span>
    let func = <span>func.</span>
    let total = "TOTAL"
    let adult_male = false
    if (["8", "10", "12", "15"].includes(this.props.section)) {
      morf = <span><b>75%</b> <span className={classes.smallFont}>(morf+mov)</span></span>
      mov = <span className={classes.smallFont}>mov</span>
      func = <span><b>25%</b> <span className={classes.smallFont}>(func)</span></span>
      total = ""
      adult_male = true
    }

    if (!shouldExpand) return null

    return (
      <TableRow>
        <TableCell className={classes.cellExpand} colSpan={8}>
          <Collapse className={classes.collapse} in={this.props.expanded} timeout="auto" unmountOnExit>
            <Box className={classes.collapseBox}>
              <Table className={classes.subtable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    {participant.jtotal.map((k, i) => {
                      return (
                        <TableCell key={i} className={classes.cellSubtable}>
                          <Link className={classes.link} to={{
                            pathname: '/judge',
                            search: `?judge=${this.getJudgeIdByIndex(i+1, "M")}&ejcode=${participant.code[0]}`
                          }}>
                            {"J" + (i + 1)}
                          </Link>
                          { this.props.judges['F'].length > 0 && participant.jfunc[i] > 0 &&
                            <React.Fragment>
                              <span> / </span>
                              <Link className={classes.link} to={{
                                pathname: '/judge',
                                search: `?judge=${this.getJudgeIdByIndex(i+1, "F")}&ejcode=${participant.code[0]}`
                              }}>
                                {"JF" + (i + 1)}
                              </Link>
                            </React.Fragment>
                          }
                        </TableCell>
                      )
                    })}
                    <TableCell className={classes.cellSubtable}>TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* morf */}
                  { participant.scores.status === ParticipantStatus.ELIMINATED_MORPHO.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{morf}</TableCell>
                      {participant.jmorf.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>elim.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.status === ParticipantStatus.RETIRED_MORPHO.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{morf}</TableCell>
                      {participant.jmorf.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>ret.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.morf > 0 &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{morf}</TableCell>
                      {participant.jmorf.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}>
                            { participant.jmorf[i] > 0 && this.getScore(participant.jmorf[i]) }
                            {" "}
                            { participant.jtotal[i] > 0 && adult_male &&
                              <React.Fragment>
                                ({participant['comp_j' + (i + 1)] || '-'})
                              </React.Fragment>
                            }
                          </TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>{this.getScore(participant.scores.morf)} ({participant.order_morf})</TableCell>
                    </TableRow>
                  }

                  {/* mov */}
                  { participant.scores.status === ParticipantStatus.ELIMINATED_MORPHO.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{mov}</TableCell>
                      {participant.jmov.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>elim.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.status === ParticipantStatus.RETIRED_MORPHO.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{mov}</TableCell>
                      {participant.jmov.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>ret.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.mov > 0 &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{mov}</TableCell>
                      {participant.jmov.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}>
                            { participant.jmov[i] > 0 && this.getScore(participant.jmov[i]) }
                          </TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>{this.getScore(participant.scores.mov)} ({participant.order_mov})</TableCell>
                    </TableRow>
                  }

                  {/* func */}
                  { participant.scores.status === ParticipantStatus.ELIMINATED_FUNC.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{func}</TableCell>
                      {participant.jfunc.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>elim.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.status === ParticipantStatus.RETIRED_FUNC.name &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{func}</TableCell>
                      {participant.jfunc.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}></TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>ret.</TableCell>
                    </TableRow>
                  }
                  { participant.scores.func > 0 &&
                    <TableRow>
                      <TableCell className={classes.cellSubtableRight}>{func}</TableCell>
                      {participant.jfunc && participant.jfunc.map((k, i) => {
                        return (
                          <TableCell key={i} className={classes.cellSubtable}>
                            { participant.jfunc[i] > 0 &&
                              <span>{this.getScore(participant.jfunc[i])} ({participant['comp_jf' + (i + 1)]})</span>
                            }
                          </TableCell>
                        )
                      })}
                      <TableCell className={classes.cellSubtable}>{this.getScore(participant.scores.func)} ({participant.order_func})</TableCell>
                    </TableRow>
                  }

                  {/* total */}
                  <TableRow>
                    <TableCell variant="head" className={classes.cellSubtableRight}>{total}</TableCell>
                    { participant.jtotal && participant.jtotal.map((k, i) => {
                      return (
                        <TableCell key={i} variant="head" className={classes.cellSubtable}>
                          { participant.jtotal[i] > 0 && !adult_male &&
                            <React.Fragment>
                              {this.getScore(participant.jtotal[i])} ({participant['comp_j' + (i + 1)] || '-'})
                            </React.Fragment>
                          }
                        </TableCell>
                      )
                    })}
                    <TableCell variant="head" className={classes.total}>{this.getScore(participant.scores.total)}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
}

ParticipantScoreCellExpand.propTypes = {
  classes: PropTypes.object.isRequired,
}

ParticipantScoreCellExpand.defaultProps = {
  isMobile: false,
}

export default withStyles(styles)(ParticipantScoreCellExpand)
