import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/core/styles';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import '../../styles/plyr.css';

import ParticipantImage from 'components/pages/participant/ParticipantImage.js';
import Video from 'components/pages/contest/Video.js';

Plyr.defaultProps['options'] = {
  controls: [
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'download',
    'fullscreen',
  ],
  hideControls: true,
  clickToPlay: true,
};

const styles = (theme) => ({
  dialog: {
    margin: -32,
  },
  title: {
    padding: '8px 32px 8px 16px',
    color: 'white',
    backgroundColor: 'black',
  },
  content: {
    padding: '0px 16px',
    minWidth: 560,
    [theme.breakpoints.down('xs')]: {
      minWidth: 285,
    },
    minHeigth: 100,
  },
  actions: {},
  subtitle: {
    marginTop: 32,
    marginBottom: 8,
  },
  img: {
    maxWidth: 320,
    width: '100%',
    height: 'auto',
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'white',
    padding: 4,
  },
  button: {
    borderRadius: 100,
    margin: 16,
    textAlign: 'right',
    fontFamily: 'Yantramanav',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '4px 16px',
  },
  arrows: {
    backgroundColor: '#fab31e',
    fill: 'white',
    marginLeft: '0.75rem',
  },
  arrowPrev: {
    backgroundColor: '#fab31e',
    fill: 'white',
    marginLeft: '0.75rem',
    transform: 'rotate(180deg)',
  },
  arrowsContainer: {
    position: 'absolute',
    marginTop: ' 1.25rem',
    right: '1rem',
  },
});

class DialogMultimedia extends React.PureComponent {
  initialState;

  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      img1: props.img1,
      video1: this.props.video1,
      video2: this.props.video2,
      video3: this.props.video3,
      list: this.props.list,
      previous: props.previous,
      next: props.next,
      render: false,
    };
    this.initialState = this.state;
  }

  handleClose = () => {
    this.setState(this.initialState);
    this.props.onClose();
  };

  handleClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        onClose={this.props.onClose}
        onClick={this.props.onClick}
        scroll={this.props.scroll}
        open={this.props.open}
      >
        <DialogTitle className={classes.title}>
          {this.state.title}
          <IconButton
            className={classes.closeButton}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.arrowsContainer}>
            {this.state.list[this.state.previous] && (
              <ArrowForwardIos
                onClick={() => this.loadPreviousParticipant()}
                className={classes.arrowPrev}
              />
            )}
            {this.state.list[this.state.next] && (
              <ArrowForwardIos
                onClick={() => this.loadNextParticipant()}
                className={classes.arrows}
              />
            )}
          </div>
          <div>
            {!this.props.img1 && (
              <>
                <Typography
                  key={1}
                  className={classes.subtitle}
                  color="secondary"
                  variant="h6"
                >
                  Foto
                </Typography>
                <p>No hay imagen disponible</p>
              </>
            )}
            {this.state.img1 && [
              <Typography
                key={1}
                className={classes.subtitle}
                color="secondary"
                variant="h6"
              >
                Foto
              </Typography>,
              <ParticipantImage
                key={this.state.img1}
                img={this.state.img1}
                alt={this.props.title}
              />,
            ]}

            {this.state.video3 && [
              <Typography
                key={1}
                className={classes.subtitle}
                color="secondary"
                variant="h6"
              >
                {this.props.video3_desc || 'Video Semifinales'}
              </Typography>,
              <Video key={2} source={this.state.video3} />,
            ]}

            {this.state.video1 && [
              <Typography
                key={1}
                className={classes.subtitle}
                color="secondary"
                variant="h6"
              >
                {this.props.video1_desc || 'Video Morfología'}
              </Typography>,
              <Video key={this.state.video1} source={this.state.video1} />,
            ]}

            {this.state.video2 && [
              <Typography
                key={1}
                className={classes.subtitle}
                color="secondary"
                variant="h6"
              >
                {this.props.video2_desc || 'Video Funcionalidad'}
              </Typography>,
              <Video key={2} source={this.state.video2} />,
            ]}

            {!this.state.video1 && !this.state.video2 && !this.state.video3 && (
              <>
                <Typography
                  key={1}
                  className={classes.subtitle}
                  color="secondary"
                  variant="h6"
                >
                  Vídeo
                </Typography>
                <p>No hay vídeo disponible</p>
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            className={classes.button}
            onClick={this.handleClose}
            color="primary"
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  loadNextParticipant() {
    const numero =
      this.state.list[this.state.next].number !== undefined
        ? this.state.list[this.state.next].number
        : this.state.list[this.state.next].pos;

    const video1 =
      this.state.list[this.state.next].medias.video1 !== undefined
        ? this.state.list[this.state.next].medias.video1
        : this.state.list[this.state.next].medias.P1;

    const video2 =
      this.state.list[this.state.next].medias.video2 !== undefined
        ? this.state.list[this.state.next].medias.video2
        : this.state.list[this.state.next].medias.P2;

    let title;
    if (this.state.list[this.state.next].type === "doma") {
      title = this.state.list[this.state.next].name
    } else {
      title = this.state.list[this.state.next].name[0]
    }

    this.setState({
      ...this.state,
      title: numero + ' - ' + title,
      img1: this.state.list[this.state.next].medias.img1,
      video1,
      video2,
      video3: this.state.list[this.state.next].medias.video3,
      previous: this.state.next,
      next: this.state.next + 1,
      render: true,
    });
  }

  loadPreviousParticipant() {
    const numero =
      this.state.list[this.state.previous].number !== undefined
        ? this.state.list[this.state.previous].number
        : this.state.list[this.state.previous].pos;

    const video1 =
      this.state.list[this.state.previous].medias.video1 !== undefined
        ? this.state.list[this.state.previous].medias.video1
        : this.state.list[this.state.previous].medias.P1;

    const video2 =
      this.state.list[this.state.previous].medias.video2 !== undefined
        ? this.state.list[this.state.previous].medias.video2
        : this.state.list[this.state.previous].medias.P2;

    let title;
    if (this.state.list[this.state.previous].type === "doma") {
      title = this.state.list[this.state.previous].name
    } else {
      title = this.state.list[this.state.previous].name[0]
    }

    this.setState({
      ...this.state,
      title: numero + ' - ' + title,
      img1: this.state.list[this.state.previous].medias.img1,
      video1,
      video2,
      video3: this.state.list[this.state.previous].medias.video3,
      previous: this.state.previous - 1,
      next: this.state.previous,
      render: true,
    });
  }
}

DialogMultimedia.propTypes = {
  classes: PropTypes.object.isRequired,
};
DialogMultimedia.defaultProps = {
  scroll: 'body',
  video4: null,
  list: [],
};

export default withStyles(styles)(DialogMultimedia);
