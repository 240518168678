import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, MenuItem, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import Spinner from "components/layout/Spinner";

const useStyles = makeStyles(theme => ({
  btnGroupSections: {
    display: "block",
    textAlign: "left",
    borderRadius: 0,
    fontWeight: "bold",
  },
  btnSection: {
    borderRadius: 0,
  },
  btnSectionSelected: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    },
  },
  btnSectionMobile: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    border: 0,
    borderRadius: 0,
    height: 48,
    color: "white",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
}));

const Sections = (props) => {
  const classes = useStyles();

  if (!props.sections) {
    return null;
  }

  if (props.currentValue && !Object.keys(props.sections).includes(props.currentValue)) {
    return (
      <Spinner />
    );
  }

  return props.isMobile ? (
    <Select
      className={classes.btnSectionMobile}
      onChange={props.changeSelectSection}
      value={props.currentValue}
      displayEmpty
      disableUnderline={true}
      IconComponent={ExpandMore}
      autoWidth={false}
    >
      <MenuItem disabled key={0} value="">
        {props.title}
      </MenuItem>
      {Object.entries(props.sections).map(([key, value]) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      })}
    </Select>
  ) : (
    <ButtonGroup className={classes.btnGroupSections} color="secondary">
      {Object.entries(props.sections).map(([key, value]) => {
        return (
          <Button
            key={key}
            className={key === props.currentValue ? classes.btnSectionSelected : classes.btnSection}
            onClick={() => props.changeButtonGroupSection(key)}
          >
            {value}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

Sections.defaultProps = {
  title: "...",
};

export default Sections;
